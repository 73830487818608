/**
*
* Top header
*
**/

.l-header {

    &__container {

        @media all and (max-width: 767px) {
            padding-bottom: 15px;
        }
    }

    &__tilt {
        position: relative;
        transform-style: preserve-3d;
        max-width: 600px;

        &--left {
            margin: {
                left: auto;
                right: 0;
            }

            @media all and (max-width: 1500px) {
                margin-left: auto;
                margin-right: 0;
            }
        }

        &--right {

            @media all and (max-width: 1500px) {
                margin-right: 20px;
            }
        }
    }

    &__image {
        position: relative;
        max-height: 420px;
        z-index: 2;
        transform: translateZ(20px);

        &--left {
            right: 20%;

            @media all and (max-width: 767px) {
                right: 16%;
            }
        }

        &--right {
            left: 20%;

            @media all and (max-width: 767px) {
                right: 27%;
            }
        }
    }

    &__image-link {
        margin-bottom: 50px;
        display: block;
        position: relative;
        height: 87%;
        overflow: hidden;

        @media all and (min-width: 768px) and (max-width: 1200px) {
            height: 82%;
        }

        &--left{
            padding-left: 15%;

          
            @media all and (max-width: 767px) {
                margin: 0 20px 0 15px;
                padding-left: 10%;
            }
        }

        &--right{
            padding-right: 15%;

            @media all and (max-width: 767px) {
                margin: 55px 0 0 25px;
            }
        }



        &-bg {
            content: '';
            position: absolute;
            bottom: 0;
            top: -10%;
            height: 120%;
            width: 120%;
            background-position: 100% 100%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: 1;

            &-left {
                left: -10%;
                background-image: url(../images/dairy-background.png); 
            }

            
            &-right {
                right: -10%;
                background-image: url(../images/beef-background.png); 

                @media all and (max-width: 767px) {
                    right: -19%;
                }
            }
        }
    }
    
    &__btn {
        position: absolute;
        bottom: 35px;
        z-index: 3;

        &--left {

            &-box {
                right: 10%; 

                @media all and (max-width: 767px) {
                    left: auto;
                    right: 10%;
                    bottom: 0;
                }
            }
        }

        &--right {

            &-box {
                left: 10%;

                @media all and (max-width: 767px) {
                    left: auto;
                    right: 10%;
                    bottom: -30px;
                }
            }
        }
    }

    &__headline {
        position: relative;
        margin-bottom: 20px;
        z-index: 4;

        @media all and (max-width: 767px) {
            margin: 1em 0 0.5em;
        }
    }
}