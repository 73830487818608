
.summary-table {
    width: 100%;
    margin: 1rem 0;

    font: {
        size: rem(20px);
        weight: 400;
    }
    color: $colorGreen;

    thead {
        tr {
            th {
                padding: 0.5rem 0;
                font: {
                    size: rem(24px);
                    weight: 400;
                }
                color: $colorGreen;

                @media screen and (min-width: 200px) and (max-width: 768px) {
                    font-size: rem(20px);
                }

                &:first-child {
                    font-weight: 600;
                    text-align: left;
                    width: 50%;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 6px 0;
                color: $colorYellow;
                vertical-align: top;

                @media screen and (min-width: 200px) and (max-width: 768px) {
                    font-size: rem(14px);
                }

                &:first-child {
                    color: $colorGreen;
                    font-weight: 600;
                }
            }
        }
    }

    &-mono-color {
        margin-bottom: 1rem;
        width: 100%;

        
        font: {
            size: rem(18px);
            weight: 400;
        }
        color: $colorGreen;

        @media screen and (min-width: 200px) and (max-width: 768px) {
            font-size: 16px;
        }

        thead {
            th {
                padding-bottom: 0.5rem;
                font-size: rem(24px);
                font-weight: 600;
                letter-spacing: rem(1px);
                color: $colorYellow;

                @media screen and (min-width: 200px) and (max-width: 768px) {
                    padding-bottom: 0;
                    font-size: rem(14px);
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 1px 0;
                }
            }
        }

        tfoot {
            font-weight: 600;
        }
    }

    &--two-cols {
        margin-top: 1rem;
        color: $colorMain;

        thead {
            tr {
                th {
                    padding: 0.5rem 0 0.25rem 0;
                    vertical-align: top;

                    &:first-child {
                        font-weight: 400;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 2px 0;
                    text-transform: none;

                    &:first-child {
                        color: $colorMain;
                        font-weight: 400;
                    }

                    &:last-child {
                        color: rgba($colorMain, 0.5);
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &--color {
        tbody {
            tr {
                td {
                    padding: 2px 0;
                    text-transform: none;

                    &:first-child {
                        color: $colorMain;
                        font-weight: 400;
                    }

                    &:last-child {
                        color: rgba($colorMain, 1);
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &--border-top {
        position: relative;

        &:before {
            content: ' ';
            position: absolute;

            top: 0;
            left: 0;

            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba($colorMain, 0.3);
        }
    }

    &--shopping-form {
        margin: 0;

        thead {
            tr {
                border-bottom: 1px solid rgba($colorMain, 0.3);
                th {
                    padding: 0.5rem 0 1rem 0;

                    font: {
                        size: rem(26px);
                        weight: 400;
                    }
                    color: $colorYellow;

                    @media screen and (min-width: 769px) and (max-width: 1024px) {
                        font-size: rem(20px);
                    }

                    @media screen and (min-width: 200px) and (max-width: 768px) {
                        font-size: rem(24px);
                    }

                    &:first-child {
                        font-weight: 400;
                        text-align: left;
                        width: 50%;
                    }
                }
            }
        }

        tbody {
            tr {

                td {
                    padding: 1rem 0;
                    color: $colorGreen;
                    vertical-align: top;

                    font-size: rem(20px);
                    letter-spacing: rem(2px);

                    @media screen and (min-width: 769px) and (max-width: 1024px) {
                        font-size: rem(16px);
                        letter-spacing: rem(1px);
                    }

                    @media screen and (min-width: 200px) and (max-width: 768px) {
                        font-size: rem(16px);
                        letter-spacing: rem(1px);
                    }

                    &:first-child {
                        color: $colorGreen;
                        font-weight: 400;
                    }
                }

                &:first-child {
                    td {
                        padding-top: 1.5rem;
                    }
                }
            }
        }
    }
}