
.product-delivery {
    display: flex;
    margin: 0.5em 0;
    padding: 0;
    flex-flow: row nowrap;
    align-items: center;
    background-color: transparent;

    &:hover {

        > svg {
            animation-play-state: running;
            fill: $colorGreen;

        }
        
        > span {
            color: $colorYellow;
        }
    }

    > svg {
        margin-right: 5px;
        fill: $colorYellow;
        animation: truck 1s infinite;
        animation-play-state: paused;
        animation-timing-function: ease;
        transition: all 1s;
    }

    > span {
        font-size: 10px;
        line-height: 12px;
        color: $colorMain;
        transition: all 0.25s ease;
    }

    &-wrapper {
        position: relative;
    }

    &__popup {
        position: absolute;
        top: -15px;
        left: 0;
        width: 100%;

        &-inner {
            position: relative;
        }

        z-index: 2;

        opacity: 0;
        visibility: hidden;

        transform: translateY(-70%);

        transition: opacity 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
                    visibility 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
                    transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

        // @media screen and (max-width: 768px) {
        //     width: calc(100% + 32px);
        //     right: 0;
        //     left: auto;
        //     transform: translateY(0);
        // }

        // @media screen and (min-width: 409px) and (max-width: 768px) {
        //     width: calc(200% + 32px);
        // }

        &--open {
            opacity: 1;
            visibility: visible;
            transform: translateY(-90%);

            // @media screen and (max-width: 768px) {
            //     transform: translateY(-50px);
            // }
        }
    }

    &__close {
        position: absolute;
        right: -12px;
        top: -24px;
        cursor: pointer;

        @media screen and (max-width: 1050px) {
            right: 10px;
        }
    }

    &__table {
        width: 100%;
        box-shadow: 0 1px 4px 0px rgba(247, 147, 30, 0.2);

        @media screen and (max-width: 410px) {
            box-shadow: none;
        }

        thead {
            tr {
                background-color: #fef3e6;

                th {
                    padding: 0.5rem 1rem;
                    text-align: left;
                    font: {
                        size: rem(14px);
                        weight: 600;
                    }
                    letter-spacing: rem(1.5px);
                    color: $colorYellow;
                }
            }
        }

        tbody {
            tr {
                background-color: #fff;

                td {
                    background-color: #fff;
                    padding: 0.5rem 0 0.5rem 1rem;
                    text-align: left;
                    font: {
                        size: rem(16px);
                        weight: 600;
                    }
                    letter-spacing: rem(1.5px);
                    
                    @media screen and (max-width: 450px) {
                        font: {
                            size: rem(14px);
                            weight: 600;
                        }
                        letter-spacing: rem(1px);
                    }
                }
            }
        }
    }
}


@keyframes truck {

    from {
        transform: translateY(0);
    }
    50% {
        transform: translateX(-2px);
    }
    to {
        transform: translateY(0);
    }
}
