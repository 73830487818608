@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700|Lato:300,400,700,900|Playfair+Display:400,700');


%latoLight {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
}

%latoRegular {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

%latoBold {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

%latoBlack {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
}

%josefinSansRegular {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
}

%josefinSansSemiBold {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
}

%josefinSansBold {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
}

%playfairDisplayRegular {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

%playfairDisplayBold {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
}