/**
*
* List
*
**/

// General
ul,
ol {
    padding: 0;
    margin: 0;
}

ul,
.list-ol {
    list-style-type: none;
}

.list-ul,
.list-ol {
    padding-top: 0.7em;
    padding-bottom: 0.7em;

    li {
        padding: 1px 0 1px 0;
        position: relative;
        margin-bottom: 2px;

        &:before {
            position: absolute;
            overflow: hidden;
            display: block;
            content: " ";
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

// Unordered list
.list-ul {
    padding-left: 30px;

    &__item, 
    li {
        &:before {
            top: .6em;
            left: -30px;
            width: 22px;
            height: 16px;

            background: url(../images/icons/cow.svg) top left no-repeat;
            background-size: 16px auto;
        }
    }
}

// Ordered list
.list-ol {
    counter-reset: item;
    padding-left: 30px;

    li {
        &:before {
            left: -30px;

            width: 25px;
            content: counters(item, ".") ". ";
            counter-increment: item;

            text-align: center;
            line-height: 1.5;
        }
    }
}