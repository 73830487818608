/**
*
* Button
*
**/

.btn {
    @include inline-block();
    color: $colorWhite;
    transition: all 0.25s;

    &--transparent {
        color: inherit;
    }

    &-transparent {
        background: transparent;
    }
}

// Size
.btn--big {
    @extend %playfairDisplayBold;
    padding: 20px 35px;
    @include dynamicFontSize(20, 40, 320, 1920);

    @media all and (max-width: 767px) {
        padding: 13px 35px;
    }
}

.btn--middle {
    @extend %latoBold;
    position: relative;
    padding: 18px 55px;
    font-size: 12px;
    text-transform: uppercase;

    @media all and (max-width: 767px) {
        padding: 18px 30px;
    }
}

.btn-wide {
    @extend %latoRegular;
    width: 350px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    text-transform: uppercase;

    @media all and (max-width: 767px) {
        width: 280px;
        font-size: 18px;
    }

    &--full {
        width: 100%;
    }

    &--small {
        width: 350px;
        height: 70px;
    }

    &--no-upper {
        text-transform: none;
    }
}

.btn--padded {
    padding: 15px 25px;
}

.btn--bigger-small {
    @extend %latoBlack;
    padding: 12px 23px 12px 23px;
    font-size: 14px;
    text-transform: uppercase;
}

.btn--small {
    @extend %latoBold;
    position: relative;
    padding: 12px 23px 12px 23px;
    font-size: 12px;
    text-transform: uppercase;
    height: 42px; //-
    line-height: initial;//-

    &-padded {
        padding-right: 32px;

        @media all and (max-width: 767px) {
            padding-right: 20px;
        }
    }

    &-no-upper {
        text-transform: none;
    }
}

.btn--no-upper {
    @include dynamicFontSize(14, 18, 320, 1920);
    font-family: Lato, sans-serif;
    font-weight: 400;
    text-transform: none;
}

.btn--full-width {
    width: 100%;
    height: 50px;
}


// Colour

$btnColors: (
    'green': $colorGreen,
    'yellow': $colorYellow,
    'white': #fff,
    'transparent': transparent
);

.btn {
    @each $colorName, $color in $btnColors {
        &--#{$colorName} {
            background-color: $color;
        }
    }
}

//Button effects

.btn--border {

    @each $colorName, $color in $colors {
        &-#{$colorName} {
            color: $color;
            border: 2px solid $color;

            &:hover {
                border-color: $colorYellow;
                background-color: $colorYellow;
                color: $colorWhite;
            }
        }
    }
}

.btn--text {

    @each $colorName, $color in $colors {
        &-#{$colorName} {
            color: $color;

            &:hover{
                
                svg {
                    transform: translate(5px);
                }
            }

            svg {
                margin-left: 5px;
                fill: $color;
                transition: all 0.25s ease;
                vertical-align: middle;
            }

            span {
                vertical-align: middle;
            }
        }
    }
}


.btn--fill-yellow {
    &:hover {
        box-shadow: inset 0 0 0 2em $colorYellow;
    }
}

.btn--fill-green {
    &:hover {
        box-shadow: inset 0 0 0 3em $colorGreen;
    }
}

.btn-with-icon-arrow-move {

    &--left {

        &:before {
            display: inline-block;
            margin-right: 10px;
            // position: absolute;
            // left: 10px;
            width: 10px;
            height: 10px;
            top: 50%;
            margin-top: -5px;
            content: "";
            background-image: url(../images/icons/left-arrow.png);
            background-size: cover;
            transition: all 0.25s;
        }

        &:hover {

            &:before {
                transform: translate(-5px);
            }
        }
    }

    &:after {
        display: inline-block;
        // position: absolute;
        margin-left: 5px;
        width: 10px;
        height: 10px;
        top: 50%;
        margin-top: -5px;
        content: "";
        background-image: url(../images/icons/right-arrow.png);
        background-size: cover;
        transition: all 0.25s;
    }

    &:hover {
        &:after {
            transform: translate(5px);
        }
    }

    // &--thin-arrow {
    //     &:after {
    //         width: 8px;
    //         height: 8px;
    //         background-image: url(../images/icons/right-arrow-thin.svg);
    //     }
    // }
}

.btn-with-icon-disappear {

    &:hover {
        box-shadow: inset 0 0 0 3em $colorYellow;

        &:after {
            display: none;
        }
    }

    &--yellow {
        &:hover {
            box-shadow: inset 0 0 0 3em $colorGreen;
        }
    }

    &:after {
        display: inline-block;
        position: absolute;
        margin-left: 10px;
        width: 10px;
        height: 10px;
        top: 50%;
        margin-top: -5px;
        content: "";
        background-image: url(../images/icons/right-arrow.png);
        background-size: cover;
    }
}


.btn--underline {
    position: relative;
    transition: all 0.3s;

    &:after {
        position: absolute;
        top: 37px;
        display: block;
        width: 50%;
        height: 1px;
        content:"";
        background-color: $colorGreen;
    }


    &:hover {
        color: $colorYellow;

        &:after {
            background-color: $colorYellow;
        }
    }
}