.colorful-table {
    overflow-x: scroll;

    &__header {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(14, 16, 320, 1920);
        border-bottom: 1px solid rgba(112, 112, 112, 0.25);
        background-color: $colorYellow;
        color: $colorMain;  

        &-item {
            position: relative;
            padding: 20px;
            min-height: 70px;
            width: 9%;
            text-align: center;

            &:first-child {
                text-align: left;
            }

            &:not(:last-child) {

                &:before {
                    position: absolute;
                    bottom: 0;
                    right: -1px;
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 70%;
                    min-height: 50px;
                    background-color: rgba(112, 112, 112, 0.25);
                    
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        bottom: auto;
                        top: auto;
                        right: 0;
                    }
                }
            }
        }
    }

    &__row {

        &:nth-of-type(odd) {
            background-color: #FEE0A0;
        }

        &:nth-of-type(even) {

            .colorful-table__col {

                &:first-child {
                    background-color: $colorYellow;
                }
            }
        }

        &:nth-of-type(odd) {

            .colorful-table__col {

                &:first-child {
                    background-color: #FFB45C;
                }
            }
        }
    }

    &__col {
        text-align: center;
        font-size: 14px;

        &:first-child {
            @extend %playfairDisplayBold;
            @include dynamicFontSize(14, 16, 320, 1920);
            padding: 10px 20px;
            text-align: left;
        }

        &:not(:last-child) {
            border-right: 1px solid rgba(112, 112, 112, 0.25);
        }
    }
}