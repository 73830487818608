.two-color-box {
    margin: -40% 0 0 70%;
    position: relative;
    width: 400px;
    max-width: 400px;

    @media all and (min-width: 751px) and (max-width: 991px) {
        margin: -25% 0 0 45%;
    }

    @media all and (max-width: 750px) {
        margin: 0;
        width: 100%;
        max-width: 100%;
    }

    &-img {
        @media all and (max-width: 750px) {
            width: 100%;
        }
    }

    &__header {
        padding: 0.7em 1em;
        @include dynamicFontSize(18, 30, 320, 1920);
    }

    &__content {
        margin: 0;
        padding: 1.5em 1.5em;
        min-height: 180px;
        @include dynamicFontSize(14, 18, 320, 1920);

        @media all and (max-width: 750px) {
            margin-bottom: 2em;
            min-height: auto;
        }


        &--with-yellow-link {

            a {
                color: $colorYellow;
            }
        }
    }
    &-position {
        position: absolute;
        left: 33%;
    }
}

