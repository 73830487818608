.promo-code {
    display: block;
    margin: 2rem 0 1rem 0;
    width: 100%;
    background: $colorWhite;

    &--failed {
        .promo-code__input {
            border-bottom: 1px solid rgba(red, 0.4);
        }
    }

    &__wrapper {
        padding: 2em;

        &--padd-small {
            padding: 1em;
        }
    }

    &__form {
        position: relative;
    }

    &--valid {
        padding: 1em;
        color: $colorGreen;
        text-align: center;
    }

    &__remove {
        display: block;
        margin: 10px auto 0;
        text-transform: uppercase;
        text-decoration: underline;
        background: none;
        transition: all 0.25s;

        &:hover {
            color: $colorYellow;
        }
    }

    &__input {
        width: 100%;
        padding: 5px;
        background-color: transparent;
        border: {
            top: 0;
            left: 0;
            right: 0;
            bottom: 1px solid $colorNormalGray;
        }

        &--no-border {
            border: 0;
        }
    }

    &__submit-btn {
        @include dynamicFontSize(14, 22, 320, 1920);
        position: absolute;
        right: 0;
        bottom: 3px;
        background-color: transparent;

        color: $colorGreen;
        transition: color 0.3s ease;
        
        &:hover {
            color: rgba($colorGreen, 0.9);
        }
    }

    &__error {
        display: block;
        // margin-top: -1rem;
        // padding-bottom: 2rem;
        padding: 0 1rem 1rem;
        text-align: center;
        font: {
            size: rem(16px);
            weight: 400;
        }
        line-height: rem(20px);
        color: rgba(red, 0.8);

        @media screen and (min-width: 200px) and (max-width: 768px) {
            padding: 1rem 0 2rem;
            font-size: 14px;
            line-height: 18px;
        }
    }
}



.promo-code__form ::-webkit-input-placeholder {
    opacity: 0.75;
    font: {
        size: rem(22px);
        weight: 400;
    }
    color: $colorGreen;

    @media screen and (min-width: 200px) and (max-width: 768px) {
        font-size: 14px;
    }
}


.promo-code__form :-moz-placeholder {
    opacity: 0.75;
    font: {
        size: rem(18px);
        weight: 400;
    }
    color: $colorGreen;

    @media screen and (min-width: 200px) and (max-width: 768px) {
        font-size: 14px;
    }
}

.promo-code__form ::-moz-placeholder {
    opacity: 0.75;
    font: {
        size: rem(18px);
        weight: 400;
    }
    color: $colorGreen;

    @media screen and (min-width: 200px) and (max-width: 768px) {
        font-size: 14px;
    }
}

.promo-code__form :-ms-input-placeholder {
    opacity: 0.75;
    font: {
        size: rem(18px);
        weight: 400;
    }
    color: $colorGreen;

    @media screen and (min-width: 200px) and (max-width: 768px) {
        font-size: 14px;
    }
}