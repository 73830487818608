/*==========  GLOBAL STYLES  ==========*/

html {
    box-sizing: border-box;
}

*, 
*:before, 
*:after {
    box-sizing: inherit;
}

html,
body,
button {
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

html {
    background: #fff;
    overflow-x: hidden;
}

body {
    @extend %latoRegular;
    @include dynamicFontSize(14, 18, 320, 1920);

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    line-height: 1.5;
    color: $colorMain;

    overflow-x: hidden;
}

ul, li {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;

    &:not([class]) {
        color: inherit;
    }

    &:not(.btn, .social-media__link) {
        transition: all 0.2s ease;
    }
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}
