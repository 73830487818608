
.product-action {
    display: flex;
    margin: 3em 0;
    flex-flow: column;
    padding: 0 0 0.25rem 2rem;
    border-left: 2px solid $colorYellow;

    &__item-change {
        position: relative;

        &-footer {
            padding-top: 5px;
            position: absolute;
            font-size: 10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__container {

        @media all and (max-width: 420px) {
            justify-content: space-between;
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        @media all and (max-width: 420px) {
            justify-content: space-between;
        }
    }

    &__price {
        min-width: 130px;
        font-size: 30px;
        color: $colorYellow;

        @media all and (max-width: 420px) {
            font-size: 24px;
        }
    }

    &__for {
        position: relative;
        top: 3px;
        display: inline-block;
        margin: 0 1rem;
        font-size: 12px;
        line-height: 15px;
        color:  $colorMain;

        &--no-ml {
            margin: 0 1rem 0 0;
        }
    }

    &__add {
        display: flex;
        margin: 1.5rem 3rem 0.5rem 0;
        padding: 0;
        flex-flow: row nowrap;
        align-items: center;
        background-color: transparent;

        @media all and (max-width: 420px) {
            margin-right: 0;
        }

        > svg {
            fill: $colorYellow;
        }

        > span {
            display: inline-block;
            margin-left: 1rem;
            font-size: 18px;
            line-height: 22px;
            color: $colorGreen;
            transition: color 0.3s ease;
        }

        &:hover {
            > span {
                color: $colorYellow;
            }
        }
    }

    &__info {
        span {
            color: $colorYellow;
        }

        &--text-sm {
            font-size: 10px;
        }
    }
}