.shopping-bag {
    position: absolute;
    width: 400px;
    top: 50px; 
    right: 58px; 
    z-index: 5;
    background-color: $colorWhite;
    transform: scale(0); 

    transition: transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    @media all and (max-width: 767px) {
        width: 100%;
        right: 0;
        top: 70px;
    }

    &__headline {
        padding: 15px;
    }

    &__image {
        width: 100%;
    }

    &-btn {
        margin: 0 0 4em 4em;

        @media all and (max-width: 420px) {
            margin-left: 1em;
        }
    }
    
    &-container {
        position: relative;

        .shopping-bag {

            @media all and (max-width: 767px) {
                top: 50px;
            }
        }
    }

    &-basket {        
        @extend %latoBold;
        display: flex;
        justify-content: flex-end;
        font-size: 18px;
        color: $colorNormalGray;
        fill: $colorYellow;
        transition: all 0.25s ease;

        @media all and (min-width: 1170px) and (max-width: 1450px) {
            font-size: 16px;
        }

        &:hover {
            fill: $colorGreen;
        }

        &__qty {
            margin-left: 10px;
        }
    }

    &--show {
        transform: scale(1);
        box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);
    }

    &__row {
        display: flex;
        
        &:nth-child(1) {
            border-bottom: 1px solid $colorGreen;
            color: $colorYellow;
        }

        &:nth-child(n+2) {
            margin-top: 15px;
        }

        &-header {
            @include dynamicFontSize(18, 22, 320, 1920);
            font-weight: 400;
        }

        &--space-between {

            @media all and (max-width: 1700px) {
                justify-content: space-between;
            }
        }

        &--no-border {
            &:nth-child(1) {
                border-bottom: none;
                color: $colorGreen;
            }
        }
    }

    &__col {
        flex: 1 0 33.333%;

        &:nth-child(3n+1) {
            @media all and (min-width: 768px) and (max-width: 1500px) {
                flex: 1 0 40%;
            }
        }

        &:nth-child(3n+2) {
            text-align: center;

            @media all and (min-width: 768px) and (max-width: 1500px) {
                flex: 1 0 10%;
            }
        }

        &:nth-child(3n+3) {
            text-align: right;

            @media all and (min-width: 768px) and (max-width: 1500px) {
                flex: 1 0 23%;
            }
        }

        &-4 {
            flex: 1 0 20%;

            &:first-child {
                flex: 1 0 40%;
            }
        }
    }

    &__total {
        @include dynamicFontSize(22, 30, 320, 1920);
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        background: $colorYellow;
        color: $colorWhite;
    }

    &__inner {
        max-height: -webkit-calc(100vh - 100px);
        max-height: calc(100vh - 100px);
        overflow: auto;
    }

    &__outer {
        position: relative;
        box-shadow: 0 0 10px 5px rgba(0,0,0,.1);

        &:before {
            position: absolute;
            top: -16px;
            right: 0;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border-right: 15px solid transparent;
            border-bottom: 17px solid $colorWhite;
            border-left: 15px solid transparent;
            z-index: 2;

            @media all and (min-width: 577px) and (max-width: 767px) {
                right: 180px;
            }

            @media all and (max-width: 576px) {
                right: 160px;
            }
        }

        &:after {
            position: absolute;
            top: -25px;
            right: -4px;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border-right: 19px solid transparent;
            border-bottom: 20px solid rgba(0,0,0,.1);
            border-left: 19px solid transparent;
            z-index: 1;
            filter: blur(2px);

            @media all and (min-width: 577px) and (max-width: 767px) {
                right: 175px;
            }

            @media all and (max-width: 576px) {
                right: 155px;
            }
        }
    }

    &__header {
        @extend %latoBold;
        @include dynamicFontSize(22, 25, 320, 1920);
        padding: 20px 10px 10px;
        display: flex;
        align-items: center;

        color: $colorYellow;

        &-basket {
            margin-right: 10px;
            fill: $colorYellow;
        }

        &-close {
            margin-left: auto;
        }
    }

    &__title {
        margin: 30px 0;
        display: flex;
        align-items: center;
        text-align: left;

        &-icon {
            margin-right: 10px;
            flex: 0 0 10%;

            @media all and (min-width:1024px) and (max-width: 1400px) {
                margin-right: 10px;
            }

            @media all and (min-width: 641px) and (max-width: 1023px) {
                margin-right: 10px;
                flex: 0 0 10%;
            }

            @media all and (max-width: 640px) {
                margin-right: 10px;
                flex: 0 0 15%;
            }
        }

        svg {
            fill: $colorYellow;

            @media all and (min-width: 641px) and (max-width: 1400px) {
                width: auto;
                height: 100%;
            }

            @media all and (max-width: 640px) {
                width: 100%;
                height: auto;
            }
        }
    }

    &__delivery-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    &-payment {
        margin-bottom: 50px;
        display: flex;

        @media all and (max-width: 1400px) {
            flex-wrap: wrap;
        }

        p {
            margin: 0;
            line-height: 1;
        }

        img {
            width: 200px;

            @media all and (max-width: 640px) {
                width: 150px;
            }
        }

        &--bt {
            align-items: flex-end;
            justify-content: space-between;
        }

        &--sm {

            p {
                margin-bottom: 10px;
                align-items: flex-start;
                font-size: 15px;
            }
    
            img {
                margin-bottom: 10px;
                width: 150px;
            }
        }
    }

    &__item {
        display: flex;

        &--select {
            display: flex;

            @media all and (max-width: 480px) {
                flex-direction: column;
                justify-content: flex-start;
            }

            > .btn {
                margin-right: 25px;

                @media all and (max-width: 480px) {
                    margin-right: 0;
                }
            }
        }

        &-font-sm {
            @include dynamicFontSize(16, 18, 320, 1920);
        }

        &-mt {
            margin-top: 10px;
        }

        &-font-lg {
            @include dynamicFontSize(18, 30, 320, 1920);
            line-height: 1;
        }

        &--space-between {
            justify-content: space-between;
        }

        &--flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--50per {
            flex: 1 1 50%;
        }

        &--big {
            @media all and (max-width: 480px) {
                flex-wrap: wrap;
            }

            .shopping-bag__item-img {
                @media all and (max-width: 480px) {
                    margin-bottom: 40px;
                    width: 100%;
                }    

                img {
                    @media all and (max-width: 480px) {
                        width: 100%;
                    }    
                }
            }
        }

        &--padd {
            padding: 10px 15px; 
        }

        &--underline {
            padding-bottom: 40px;
            border-bottom: 1px solid $colorNormalGray;
        }

        &-img {
            // width: 180px;
            width: 40%;
            max-width: 180px;

            @media all and (max-width: 480px) {
                max-width: 100%;
            }

            &--popup {
                max-width: 135px;

                @media all and (max-width: 500px) {
                    margin-bottom: 20px;
                    max-width: 100%;
                }
            }
        }

        &-desc {
            display: flex;
            flex-direction: column;
            flex-basis: 45%;
            font-size: 16px;

            @media all and  (min-width: 321px) and (max-width: 420px) {
                flex-basis: 50%;
            }

            @media all and (max-width: 320px) {
                flex-basis: 60%;
            }

            &--lg {
                flex-grow: 1;
                margin: 0 10px;
                font-size: 18px;

                @media all and (max-width: 420px) {
                    margin: 0;
                }
            }
        }

        &-remove {
            margin-top: auto;
            padding: 0;
            text-align: left;
            background-color: transparent;
            color: $colorGreen;
            text-decoration: underline;
            text-transform: uppercase;

            &--sm {
                margin-top: 10px;

                @media all and (max-width: 1200px) {
                    margin-top: 0;
                }
            }
        }

        &-summary {
            position: relative;
            display: flex;
            flex-direction: column;

            &--mr-mt {
                margin: auto auto 0 0;
            }

            &--right {
                flex-basis: 20%;
            }
        }

        &-total-flex {
            margin-top: auto;
        }

        &-total {
            flex-grow: 1;
            font-size: 16px;
        }

        &-change {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            button {
                margin: 0;
                padding: 0;
                background-color: transparent;

                svg {
                    fill: $colorGreen;
                    transition: fill 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                }

                &:hover {
                    svg {
                        fill: $colorYellow;
                    }
                }
            }
        }

        &-quantity {
            font-size: 30px;
            line-height: 12px;
            color: $colorYellow;
            text-align: center;
            background-color: transparent;

            &--input {
                display: inline-block;
                width: 80px;
                margin: 0 10px;
                padding: 4px 10px;
                pointer-events: auto;
                border: 2px solid $colorGreen;
                text-align: center;

                &-small {
                    padding: 4px 5px;
                    width: 46px;
                    height: 36px;
                    font-size: 24px;
                    line-height: 12px;
                }

                &-middle {
                    padding: 4px 0;
                    width: 80px;
                    height: 60px;
                    background: $colorWhite;

                    @media all and (min-width: 12001px) and (max-width: 1400px) {
                        width: 60px;
                        height: 45px;
                    }

                    @media all and (max-width: 1200px) {
                        width: 46px;
                        height: 36px;
                        font-size: 22px;
                    }
                }
            }

            &--sm {
                width: 70px;
                padding: 4px 6px;
            }
        }
    }

    &__item-left {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    &__item-name {
        @extend %playfairDisplayBold;
        font-size: 18px;
        flex-basis: 100%;

        &--sm {
            flex-basis: 20%
        }
    }

    &__summary {
        margin-top: 20px;
        padding: 15px;
        background: $colorSmokyWhite;

        &--md {
            margin-top: 30px;
            padding: 35px; 
        }

        &--mb-small {
            margin: 30px 0 15px;
        }

        &-headline {
            @extend %latoBold;
            margin: 0 0 10px;
            font-size: 18px;

            &--font-large {
                @include dynamicFontSize(14, 22, 320, 1920);
                font-size: 22px;
            }
        }
    }

    &__summary-box {
        padding: 1em 3em;
        
        background-color: $colorGreen;

        @media all and (min-width: 421px) and (max-width: 1200px) {
            max-width: 100%;
        }

        @media all and (max-width: 420px) {
            padding: 1em;
            max-width: 100%;
        }

        &--marg {
            max-width: 545px;
            margin: 2em 0 2em 6em;

            @media all and (min-width: 421px) and (max-width: 1200px) {
                margin: 2em auto 2em;
            }
    
            @media all and (max-width: 420px) {
                margin: 2em auto 2em;
            }
        }

    }

    &__table {
        font-size: 16px;
        color: $colorGreen;

        &--font-large {
            @include dynamicFontSize(16, 22, 320, 1920);
        }

        &-row {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            &--bold {
                font-weight: 600;
            }

            &--no-mb {
                margin-bottom: 0;
            }
        }

        &-header {
            @include dynamicFontSize(18, 22, 320, 1920);
            font-weight: 400;
        }

        &--bordered {
            margin: 1em 0;
            padding: 1em 0;
            border-top: 1px solid $colorGray;
            border-bottom: 1px solid $colorGray;
        }
    }

    &__btns {
        padding: 20px 15px;
        display: flex;
        align-items: stretch;

        @media all and (max-width: 420px) {
            padding: 20px 10px;
        }
    }

    &__btn {
        @extend %latoRegular;
        padding: 0 10px;
        min-height: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-basis: 50%;
        font-size: 15px;
        text-transform: uppercase;

        @media (max-width: 420px) { 
            font-size: 14px;
        }

        &:first-child {
            margin-right: 7px;

            @media all and (max-width: 420px) {
                margin-right: 5px;
            }
        }

        &:nth-child(2) {
            margin-left: 7px;

            @media all and (max-width: 420px) {
                margin-left: 5px;
            }
        }

        &-wide {
            margin: 30px 0;
            width: 100%;
        }
    }

    &-bg-image {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: top center !important;

        @media all and (max-width: 1200px) {
            background-image: none !important;
        }

        &--height {
            min-height: 60vh;

            
            @media all and (min-width: 1900px)  {
                height: 90vh;
            }

            @media all and (min-width: 1700px) and (max-width: 1899px) {
                height: 80vh;
            }
        }

        &__img {
            display: none;
        }
    }

}