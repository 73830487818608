/**
*
* Forms
*
**/

.form {
    position: relative;

    fieldset {
        @include clearfix();
    }

    &--narrow {
        padding: 0 4em 2em;

        @media all and (max-width: 768px) {
            padding: 0 2.5em 2em;
        }
    }

    input[type=checkbox] {
        opacity: 0;
    }
      
    input[type=checkbox] + label {
        margin: 0 0 0 10px;
        position: relative;
        cursor: pointer;
    }
    
    input[type=checkbox] + label ~ label {
        margin: 0 0 0 40px;
    }
    
    input[type=checkbox] + label::before {
        content: ' ';
        position: absolute;
        top: 7px;
        left: -24px;
        // top: 50%;
        // margin-top: -7px;
        width: 14px;
        height: 14px;
        display: block;
        background: $colorSmokyWhite;
        border: 1px solid $colorGray; 
    }
    
    input[type=checkbox] + label::after {
        content: '';
        position: absolute;
        top: 3px;
        // top: -1px;
        left: -20px;
        width: 18px;
        height: 18px;
        display: block;
        z-index: 1;
        background: url(../images/icons/tick.svg) no-repeat center center;;
        background-size: cover;
        transition: all .3s ease;
        transform: scale(0);
        opacity: 0;
    }
    
    input[type=checkbox]:checked + label::after {
        transform: scale(1);
        opacity: 1;
        z-index: 20;
    }    

    &__checkbox {

        &--absolute {
            position: absolute;
        }

        &-label {
            
            &--marg-none {
                margin: 0 !important; 
            }

            &-text {
                position: relative;
                left: 5px;
            }
        }
    }
}
.form-wrapper {
    padding: 0 2em;
    max-width: 570px;
    margin: 0 auto;

    @media all and (min-width: 421px) and (max-width: 991px) {
        padding: 0 3em;
        max-width: 100%;
    }

    @media all and (max-width: 420px) {
        padding: 0 1em;
    }
}

// General
.form__input, 
.form__textarea, 
select, 
.form__select {  
    width: 100%; //115%
    height: 50px;
    border: solid 1px $colorGray; 
    padding: 0.8em 0.67em;
    outline: none;

    &--small {
        width: 100px;
    }

    // &:focus {
    //     border-color: $colorGreen;
    // }
}

select {
    line-height: 1;
}


// Inputs & textareas
.form__input, 
.form__textarea,
.form__select, { 
    background-color: $colorSmokyWhite;
    color: $colorGreen;
}
    
.ie9 .form__input {
    line-height:normal;
} /* Get the stuff to line up right */
    
.form__textarea {
    width: 100%;
    min-height: 10em;
}


// Select list
.form__select {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    max-width: 600px;

    select {
        width:100%;
        background-color:transparent;
        background-image:none;
        -webkit-appearance: none;
        border:none;
        box-shadow:none;
        background: url(../images/icons/drop-down-arrow.svg);
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: 90% 50%;
    }
}

.form__select {

    &-box {
        display: flex;

        &-item {
            flex: 1 1 46%;

            @media screen and (max-width: 991px) {
                flex: 1 1 48%;
            }

            &:nth-child(1) {
                margin-right: 8%;

                @media screen and (max-width: 991px) {
                    margin-right: 4%;
                }
            }
        }
    }
}

// Label above the field
.form__label {
    display: block;
    width: 100%;
    line-height: 2;
    // color: $colorGreen;
}


.form__row {
    display: table;
    width: 100%;
    clear:both;
    padding: 6px 0;

    &--mob {

        @media all and (max-width: 576px) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &--left,
    &--right {
        position: relative;
        display: table-cell;
        vertical-align: top;
        width: 48%;

        @media screen and (max-width: 991px) {
            width: 100%;
            display: block;
        }

        &:empty {
            @media screen and (max-width: 991px) {
                padding-top: 0;
            }
        }
    }

    &--left {
        // width: 50%;
        padding-right: 0;

        @media screen and (max-width: 991px) {
            padding-bottom: 0.5rem;
            width: 100%;
        }
    }

    &--right {
        padding-left: 1.2em;

        @media screen and (max-width: 991px) {
            padding-top: 0.5rem;
            padding-left: 0;
        }
    }

    &--25 {
        width: 23%;
    }

    &--75 {
        width: 73%;
    }

    &--40 {
        width: 38%;
    }

    &--60 {
        width: 58%;
    }

    &--25,
    &--75,
    &--40,
    &--60 {
        @media screen and (max-width: 1023px) {
            width: 100%;
        }
    }

    &-left,
    &-right {
        position: relative;
        display: table-cell;
        vertical-align: top;

        @media all and (max-width: 576px) {
            padding: 0 !important;
            width: 100% !important;
        }
    }

    &-left {
        width: 50%;
    }

    &-right {
        padding-left: 1.6em;
        width: 48%;
    }

    &-25 {
        width: 25%;
    }

    &-75 {
        padding-left: 1.6em;
        width: 73%;
    }

    &-container {
        position: relative;
        margin: {
            left: 27px;
            right: 10px;
        }
    }
}

.form__response {
    border-radius: 5px;

    &--ok {
        background: $colorGreen;
    }

    &--error {
        background: red;
    }

    p {
        padding: 10px;

        font-size: 1em !important;
        color: #fff;
        text-shadow: none;
    }
}

#contact-mail {
    display: none;
}

::-webkit-input-placeholder { 
    color: $colorLightGraySecondary;
}
:-moz-placeholder { 
    color: $colorLightGraySecondary;
}
::-moz-placeholder {
    color: $colorLightGraySecondary;
}
:-ms-input-placeholder { 
    color: $colorLightGraySecondary;
}


.form__btn {
    margin: 0 auto;
    width: 215px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 991px) {
        width: 100%;
    }

    &--transparent {
        transition: all 0.3s ease;

        &:hover {
            background-color: $colorYellow;
        }
    }

    &--small {
        width: 125px;
        height: 50px;
    }

    &--full-width {
        width: 100%;
    }

    &--right {
        margin-left: auto;
        margin-right: 0;

        @media all and (min-width: 577px) and (max-width: 991px) {
            width: 215px;
        }

        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
}


.form__radio {

    &-label {
        position: relative;
        display: inline-block;
        cursor: pointer;

        &:before {
            content: ' ';
            display: inline-block;
            position: relative;
            top: 2px;
            left: -2px;
            margin-right: 8px;
            border: 2px solid $colorYellow;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            background-color: transparent;
            transition: background-color 0.3s linear;
        }

        &:after {
            content: ' ';
            position: absolute;
            top: 7px;
            left: 2px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $colorYellow;
            opacity: 0;
            transform: scale(0) rotate(-45deg);
            transition: opacity 0.3s linear,
                        transform 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

            @media all and (-webkit-min-device-pixel-ratio:0) {
                top: 6px;
            }

            @supports (-ms-ime-align:auto) {
                top: 7px;
            }
        }

        &--large {
            font-size: 18pz;

            @media all and (min-width: 200px) and (max-width: 768px) {
                font-size: rem(14px);
                line-height: 1.7;
            }

            &:before {
                top: 3px;
                height: 20px;
                width: 20px;
            }

            &:after {
                top: 8px;
                left: 3px;
                width: 10px;
                height: 10px;
            }
        }
    }

    &:checked {

        + .form__radio-label {
            position: relative;

            &:after {
                opacity: 1;
                transform: scale(1) rotate(-45deg);
            }
        }

    }

    &--right {
        + .form__radio-label {

            &:before {
                margin-left: 8px;
                margin-right: 0;
                float: right;
            }
        }

        &:checked {

            + .form__radio-label {
                position: relative;

                &:after {
                    left: auto;
                    right: 4px;
                }
            }
        }
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form__show-more {
    display: block;
    margin: .5rem 0;
    text-decoration: underline;
    transition: color .3s ease;
    color: $colorMain;

    &:hover {
        color: $colorYellow;
    }
}