
$mainContainerMaxWidth: 1645;
$middleContainerMaxWidth: 1200;
$narrowContainerMaxWidth: 970;
$supreNarrowContainerMaxWidth: 770;

.container {
    margin-right: auto;
    margin-left: auto;

    width: 92%;
    max-width: ($mainContainerMaxWidth)+px;

    &-superNarrow {
        @extend .container;
        max-width: ($supreNarrowContainerMaxWidth)+px;
    }

    &-narrow {
        @extend .container;
        max-width: ($narrowContainerMaxWidth)+px;
    }

    &-middle {
        @extend .container;
        max-width: ($middleContainerMaxWidth)+px;
    }

    &-fluid {
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
    }
}
