/**
*
* Content (text stylisation connected to CMS WYSIWYG editor)
*
**/
.content {

    p,
    li,
    td {
        font-size: 18px;
        line-height: 1.4;
        // font-weight: 300;

    }

    .content__very-small-text {
        font-size: 10px;
    }
    
    .content__small-text {
        font-size: 14px;
    }

    .content__large-text {
        @extend %latoLight;
        @include dynamicFontSize(16, 26, 320, 1920);
    }

    .content__large-bold-text {
        @extend %latoBold;
        @include dynamicFontSize(18, 24, 320, 1920);
        @include dynamicLineHeight(22, 32, 320, 1920);
    }

    .content__middle-text {
        @extend %latoLight;
        @include dynamicFontSize(16, 20, 320, 1920);
    }

    p:not(:last-child) {
        // margin: 0;
        padding-bottom: .9em;
    }

    ul:not(:last-child),
    ol:not(:last-child) {
        padding-bottom: 1em;
    }

    // p {
    //     margin-top: 0;
        // margin: 1em 0;

        // &:last-child {
        //     margin-bottom: 0;
        // }
    // }

    p + ul,
    p + ol {
        //margin-top: -.7em !important;
    }

    /* uls, ols */
    // ul {
    //     @extend .list-ul;
    // }

    ul {
        @extend .list-ul;
    }

    ol {
        @extend .list-ol;
    }

    blockquote {
        @extend .blockquote;
    }
}