.input-icon {
    display: flex; 

    &__field {
        @extend %latoBold;
        padding: 0.6rem 2.5rem;
        width: 300px;
        border: 0;
        background-color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        color: $colorGreen;
    }

    input[type="text"] { 
        color: $colorGreen;
    }
    input[type="text"]::-webkit-input-placeholder {
        color: $colorGreen;
    } 
    input[type="text"]::-moz-placeholder {
        color: $colorGreen;
    }

    &__btn {
        padding: 0.95rem 1.2rem;
        background-color: $colorGreen;
        border: 0;
        transition: all 0.2s ease;

        &:hover,
        &:focus {
            background-color: $colorYellow;
        }
    }
}

.article-flex {
    display: flex;
    flex-wrap: wrap;

    &__item {
        flex-basis: 50%;
    }
}