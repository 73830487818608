/**
*
* Breadcrumbs
*
**/

.breadcrumbs {
    @extend %latoBold;
    clear: both;
    padding: 1em 0;
    color: $colorGreen;
    font-size: 16px;

    @media all and (max-width: 480px) {
        font-size: 14px;
    }

    &--mob-ml {

        @media all and (min-width: 481px) and (max-width: 767px) {
            margin-left: 50px;
        }

        @media all and (max-width: 480px) {
            margin-left: 45px;
        }
    }

    &--mob-mb {

        @media all and (max-width: 767px) {
            margin-bottom: 30px;
        }
    }


    li {
        display: inline-block;

        &:not(:last-child):after {
            content: '/';
            padding: 0 10px;
            color: $colorGreen;
        }
    }

    a {
        color: $colorYellow;

        &:hover {
            color: #555;
        }
    }
}