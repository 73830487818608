.box {
    position: relative;
    padding: 50px 90px;
    margin: 5em 0 5em -10em;
    max-width: 620px;
    // max-height: 540px;

    &--right {
        margin: {
            left: auto;
            right: -10em;
        }
        padding: 80px;
        max-width: 520px;
    }

    @media all and (max-width: 767px) {
        margin: 0;
        padding: 60px 25px 50px;
        max-width: 100%;
        width: 100%;
        // max-width: 300px;
        // max-height: auto;
    }

    &__headline-underline {
        position: relative;
        margin-bottom: 50px;
        padding-bottom: 20px;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 4px;
            width: 150%;
            background-color: $colorYellow;

            @media all and (max-width: 768px) {
                width: 100%;
            }
 
        }
    }

    &-info {
        h1, 
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 15px;            
            padding: 10px 70px 20px;
            display: inline-block;

            @media all and (max-width: 677px) {
                padding: 10px 20px;
            }
        }
    }

    &-with-label {
        
        & * {
            vertical-align: middle;
        }

        span {
            margin-left: 15px;
        }
    }

    &-payment {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
            width: 200px;

            @media all and (max-width: 640px) {
                width: 150px;
            }
        }

        p {
            margin-bottom: 10px;
        }

        img {
            margin-bottom: 10px;
        }
    }
}