.label {
    display: flex;
    align-items: center;
    height: 34px;
    font-size: 16px;

    &-container {
        margin: 3em 0 4em;
        position: relative;
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 767px) {
            margin-bottom: 30px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__basket {
        margin-left: auto;
        
        @media all and (max-width: 767px) {
            align-self: flex-end;
            margin: 30px 0 0;
        }
    }

    &__home-icon {
        position: relative;
        z-index: 2;
    }

    &__arrow {
        position: relative;
        display: inline-block;
        margin: 0 25px 0 -20px;
        padding: 6px 20px 6px 30px;
        height: 36px;
        background-color: #ededed;
        color: #244722;

        &:before {
            position: absolute;
            top: 0px;
            right: -20px;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border-top: 18px solid transparent;
            border-left: 20px solid #ededed;
            border-bottom: 18px solid transparent;
            z-index: 1;
        }
    }

    &__ribbon {
        position: relative;
        display: inline-block;
        margin: 0 32px 0 -20px;
        padding: 6px 20px 6px 30px;
        height: 36px;
        background-color: $colorYellow;
        color: $colorWhite;

        &:before {
            position: absolute;
            top: 0px;
            left: 0;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border-top: 18px solid transparent;
            border-left: 20px solid $colorWhite;
            border-bottom: 18px solid transparent;
        }
    }
}