
.popup {
    position: relative;
    padding: 40px 35px 30px;
    width: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $colorWhite;


    &__img {
        max-height: 70vh;
    }

    &--padded {
        padding: 50px 60px;
    }


    &--small {
        width: 560px;
    }

    &--big {
        width: auto;
    }

    @media screen and (min-width: 421px) and (max-width: 767px) {
        width: 100%;
    }

    @media screen and (max-width: 420px) {
        padding: 30px 20px 30px;
        width: 100%;
    }

    &__close {
        position: absolute;
        top: 35px;
        right: 35px;
        z-index: 2;

        background: none;
    }

    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;

        width: 100%;
        height: 100%;
        background-color: rgba($colorMain, 0.5);

        // opacity: 1;
        // visibility: visible;
        opacity: 0;
        visibility: hidden;

        transition: opacity 0.8s ease,
                    visibility 0.8s ease;

        @media screen and (min-width: 421px) and (max-width: 767px) {
            padding: 0 3em;
        }
    
        @media screen and (max-width: 420px) {
            padding: 0 2em;
        }

        &--show {
            opacity: 1;
            visibility: visible;
        }
    }

    &__btns {
        display: flex;
        flex-wrap: wrap;

        @media all and (max-width: 600px) {
            flex-direction: column;
        }
    }

    &__btn {

        @media all and (max-width: 600px) {
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
        }

        &--full-width {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
        }
    }
}
