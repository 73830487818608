.contact {
    &-item {
        padding: 5px 0;
        transition: all 0.25s;

        &:hover {
            svg {
                fill: $colorGreen;
            }
        }
    }
    svg {
        fill: $colorYellow;
        transition: all 0.25s;
    }
}