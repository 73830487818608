.left-nav {
    margin-right: 50px;
    padding: 20px;
    background-color: $colorSmokyWhite;
    transition: all 0.3s ease;

    @media all and (max-width: 767px) {
        margin-right: 0;
    }
    
    p {
        margin: 0;
    }

    &--mb {
        margin-bottom: 20px;
    }

    &--no-pt {
        padding: 0 20px 20px;
    }

    &--no-pt-pb {
        padding: 0 20px;
    }

    &--pt-pb {
        padding: {
            top: 20px;
            bottom: 20px;
        } 
    }

    &__sub-menu {
        margin: 10px 0;
        position: relative;
        display: inline-block;
        padding: 0 30px 0 0;

        cursor: pointer;

        &-arrow-right {

            &:after {
                display: inline-block;
                position: absolute;
                margin-top: -6px;
                width: 12px;
                height: 12px;
                top: 50%;
                right: 12px;
    
                content: "";
                background-image: url(../images/icons/right-arrow.svg);
                background-size: cover;
                transition: all 0.22s;
            }
        }

        &-arrow-down {

            &:after {
                display: inline-block;
                position: absolute;
                margin-top: -6px;
                width: 12px;
                height: 12px;
                top: 50%;
                right: 12px;
    
                content: "";
                background-image: url(../images/icons/right-arrow.svg);
                background-size: cover;
                transition: all 0.22s;
                transform: rotate(90deg);
            }
        }

        &-active {
            padding: 10px 30px 10px 10px;
            background-color: $colorYellow;
            color: $colorWhite;

            &:after {
                background-image: url(../images/icons/right-arrow--white.svg);
            }
        }

        &-item {
            position: relative;
            margin: 10px 0 0 10px;
            padding: 5px;         
            color: $colorWhite;
            border: 0.5px solid transparent;
            transition: all 0.2s;
            cursor: pointer;

            &:hover {
                border-color: $colorWhite;
            }

            &--active {
                border-color: $colorWhite;
            }
        }
    }

    &__menu {
        @extend %playfairDisplayRegular;
        @include dynamicFontSize(16, 18, 320, 1920);
        color: $colorGreen;

        &-item {
            padding: 10px 20px; 
            transition: all 0.25s;

            a {
                color: $colorGreen;
            }

            &:hover {
                a {
                    color: $colorYellow;
                }
            }

           &-sub-menu {
                position: relative;
                padding-bottom: 20px;
                transition: all 0.3s ease;

                &:before {
                    position: absolute;
                    top: 32px;
                    left: -11%;
                    content: "";
                    display: inline-block;
                    height: 85%;
                    width: 122%;
                    background-color: $colorGreen;
                }
            }
        }
    }

    &__item {
        @extend %playfairDisplayRegular;
        @include dynamicFontSize(18, 26, 320, 1920);

        position: relative;
        display: inline-block;
        padding: 6px 30px 9px;
        width: 100%;
        cursor: pointer;
        color: $colorGreen;

        @media all and (max-width: 767px) {
            padding: 6px 20px 9px;
        }

        &-arrow-right {
            &:after {
                display: inline-block;
                position: absolute;
                margin-left: 25px;
                margin-top: -6px;
                width: 12px;
                height: 12px;
                bottom: 17px;
                right: 20px;
    
                content: "";
                background-image: url(../images/icons/right-arrow--green.svg);
                background-size: cover;
                transition: all 0.25s;

                @media all and (max-width: 767px) {
                    top: 50%;
                    right: 5px;
                }
            }
        }

        &-arrow-down {
            &:after {
                display: inline-block;
                position: absolute;
                margin-left: 25px;
                margin-top: -6px;
                width: 12px;
                height: 12px;
                bottom: 17px;
                right: 20px;
    
                content: "";
                background-image: url(../images/icons/right-arrow--green.svg);
                background-size: cover;
                transition: all 0.25s;
                transform: rotate(90deg);

                @media all and (max-width: 767px) {
                    top: 50%;
                    right: 5px;
                }
            }
        }

        &-active {
            background-color: $colorYellow;
            color: $colorWhite;

            &:after {
                background-image: url(../images/icons/right-arrow--white.svg);
            }
        }
    }
}