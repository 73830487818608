.three-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    @media all and (max-width: 767px) {
        flex-direction: column;
    }

    &__item {
        width: 100%;
        flex-basis: 27%;
        text-align: center;
        color: $colorGreen;

        &:nth-child(n+4) {
            @media all and (max-width: 767px) {
                margin-bottom: 40px;
                padding: 20px;
                background-color: $colorSmokyWhite;
                border-top: 2px solid $colorGreen;
            }
        }

        &:nth-child(1) {
            @media all and (max-width: 767px) {
                order: 1;
            }
        }
        &:nth-child(2) {
            @media all and (max-width: 767px) {
                order: 3;
            }
        }
        &:nth-child(3) {
            @media all and (max-width: 767px) {
                order: 5;
            }
        }
        &:nth-child(4) {
            @media all and (max-width: 767px) {
                order: 2;
            }
        }
        &:nth-child(5) {
            @media all and (max-width: 767px) {
                order: 4;
            }
        }
        &:nth-child(6) {
            @media all and (max-width: 767px) {
                order: 6;
            }
        }
    }
}