.social-button {
    display: block;
    max-width: 200px;
    margin: 0 auto 1.5rem auto;
    padding: 0.6em 60px;

    border: 1px solid $colorMain;
    color: $colorMain;
    transition: background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    @media screen and (min-width: 200px) and (max-width: 1024px) {
        width: 100%;
    }

    @each $name, $color_code in $socials {
        &--#{$name} {
            background: url(../images/#{$name}.png);
            background-repeat: no-repeat;
            background-position: 12px center;

            &:hover {
                color: $color_code;
                border-color: $color_code;
            }
        }
    }

    &-container {
        padding-bottom: 2em;
    }
}