.article-box {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__link {
        align-self: flex-start;

        &--bottom {
            margin-top: auto;
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        
        &:hover {
            .article-box-top-img__image {
                transform: scale(1.1);
            } 
        }
    }

    &--padd {

        &-sm {
            padding: 1em 1em 2em;
        }

        &-md {
            padding: 2em;
        }

        &-lg {
            padding: 2em 4em;
    
            @media all and (max-width: 968px) {
                padding: 2em;
            }
        }

    }


    &--ml-mr {
        @media all and (max-width: 767px) {
            margin: 0 20px;
        }
    }


    &__headline {
        margin-bottom: 1em;

        @media all and (max-width: 767px) {
            margin: 0 0 0 20px;
        }
    }

    &--no-height {
        height: auto;
    }

    &__btn {
        position: absolute !important;
        right: 20px;
        bottom: -25px;

        &--left {
            right: auto;
            left: auto;
        }
    }

    &__to-bottom {
        margin-top: auto;
    }

    
    &-top-img {
        position: relative;
        display: flex;
        max-height: 270px;
        overflow: hidden;

        // &:hover {
        //     .article-box-top-img__image {
        //         transform: scale(1.1);
        //     } 
        // }

        &__image {
            width: 100%;
            transition: all 0.5s ease;
            align-self: flex-start;
        }
    }
}