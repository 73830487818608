.loading {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    background: rgba(#fff, 0.8) url(../images/ajax-loader.gif) center center no-repeat;
}