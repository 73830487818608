.language {
    
    @media all and (max-width: 1023px) {
        margin-bottom: 20px;
    }

    &__select {
        color: $colorMain;
        background-color: transparent;
        border: none;
    }

    .sb {
        &Holder {
            width: 200px; //190
            position: relative;

            display: flex;
            align-items: center;

            background: url(../images/icons/drop-down-arrow.png);
            background-repeat: no-repeat;
            background-size: 11px;
            background-position: 100% 50%;
        }

        &Selector {
            padding: 10px 0;
            display: block;
            color: $colorMain;

            &Image {
                margin: 0 10px;
            }
        }

        &Options {
            position: absolute;
            top: 45px !important;
            padding: 15px 15px 0;
            width: 205px; //195
            color: $colorMain;  
            background-color: $colorWhite;
            z-index: 10;

            &-flag {
                display: flex;
                align-items: center; 

                &:before {
                    content: "";
                    margin-right: 10px;
                    display: inline-block;
                    width: 30px;
                    height: 20px;
                    background-repeat: no-repeat;
                }

                &--uk {

                    &:before {
                        background: url(../images/uk-flag.png);
                    }
                }

                &--row {

                    &:before {
                        background: url(../images/row-flag.png);
                    }
                }
            }



            li {
                padding-bottom: 15px;

                a {
                    color: $colorMain;  
                }
            }

            .selected {
                opacity: 0.5;

                a {
                    color: $colorMain;  
                }
            }
        }
    }
}
