.article-image-block {
    display: flex;

    @media all and (max-width: 767px) {
        display: block;
    }

    &__article {
        padding: 2em 10em 2em 5em;
        flex-basis: 60%;
        
        @media all and (max-width: 767px) {
            padding: 2em;
        }
    }

    &__image {
        position: absolute;
        right: -30px;
        top: 30px;
        width: 150%;
        min-height: 100%;
        height: auto;
        background-size: cover;
        background-position: top center;

        @media all and (max-width: 767px) {
            display: none;
        }
    }

    &__item {
        position: relative;
    }
}