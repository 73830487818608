.center-block {
    position: relative;
    margin-top: -30px;
    padding: 2em 7em 3em 7em;

    @media  all and (max-width: 650px) {
        margin-top: -15px;
        padding: 2em 3em 3em 3em;
    }

    &__btn {
        position: absolute;
        right: 85px;
        bottom: -25px;

        @media all and (max-width: 767px) {
            right: 30px;
        }
    }

    &:before {
        position: absolute;
        top: 25px;
        left: 0;
        display: block;
        width: 160px;
        height: 160px;
        content: '';
    }

    &--hourglass-icon {

        &:before {
    
            background-image: url(../images/backgrounds/genetic.svg);
        }  
    }

    &--car-icon {

        &:before {
    
            background-image: url(../images/backgrounds/genetic.svg);
        }  
    }
}