.social {

    &-block {
        margin-top: 20px;
        display: flex;

        &__item {
            margin-right: 10px;
            // background: #fff;
            

            &-twitter {
                fill: #50ABF1;
                transition: all 0.3s ease;

                &:hover {
                    fill: #2a99ef;
                }
            }

            &-fb {
                fill: #3A559F;
                transition: all 0.3s ease;

                &:hover {
                    fill: #2f4683;
                }
            }
        }
    }

    &-box {
        // margin-top: 3em;
        padding: 0 3em 2em;
        text-align: center;
        word-break: break-word;
    
        @media all and (max-width: 767px) {
            padding: 0 1.5em 2em;
            text-align: left;
        }

        &--mt {
            margin-top: 3em;
        }
    
        &__headline {
            margin: -5em 0 0.5em;
            width: 7em;
            display: inline-block;
            text-align: center;
        }

        &__link {
            display: flex;
            align-items: center;

            &-text {
                @extend %latoRegular;
                font-size: 14px;
                color: $colorWhite;

                span{
                    @extend %latoBold;
                    font-size: 20px;
                    transition: all 0.3s; 
                }
            }

            &:hover {
                .social-box__link-text span{
                    color: $colorYellow;
                } 
            }
        }
    }
}
