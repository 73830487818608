.inline-tab {
    margin: 0.5em 0 1em;
    display: flex;
    flex-wrap: wrap;

    li {
        &:not(:last-child) {
            margin-right: 1em;
        }
    }

    &__name {
        @include dynamicFontSize(18, 30, 320, 1920);
        position: relative;
        color: $colorMain;

        @media all and (max-width: 991px) {
            font-size: 18px;
        }

        &--active {
            &:after {
                position: absolute;
                left: 0;
                bottom: -5px;
                height: 3px;
                width: 100%;
                content: "";
                background-color: $colorYellow;
                transition: width .3s;
            }
        }

        &:after {
            position: absolute;
            left: 0;
            bottom: -5px;
            height: 3px;
            width: 0;
            content: "";
            background-color: $colorYellow;
            transition: width .3s;
        }
            
        &--active {
            &:after {
                width: 100%;
            }
        }

        &:hover {
            &:after {
                width: 100%;
            }
        }
    }
}