.image-card {
    margin-bottom: 20px;
    flex-basis: 32%;

    display: flex;
    flex-direction: column;

    &:nth-child(3n+2) {
        margin: 0 2% 20px;

        @media all and (max-width: 1250px) {
            margin: 0 0 20px;
        }
    }

    &:nth-child(odd) {

        @media all and (min-width: 769px) and (max-width: 1250px) {
            margin-right: 2%;
        }

        @media all and (max-width: 768px) {
            margin-right: 0;
        }
    }

    @media all and (min-width: 769px) and (max-width: 1250px) {
        flex-basis: 49%;
    }

    @media all and (max-width: 768px) {
        flex-basis: 100%;
    }

    &__form {
        display: inline-block;

        &-btn {
            background-color: transparent;
        }
    }

    
    &__info {
        position: relative;
        padding: 20px 50px 20px 20px ;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @media all and (max-width: 1500px) {
            padding: 20px;
        }
    }

    &__btns {
        margin-top: auto;
    }

    &__image {
        overflow: hidden;
        max-height: 290px;
        cursor: pointer;

	    img{
            width: 100%;
            transition: all 0.3s ease;
        }
        
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    &__price {
        @include dynamicFontSize(24, 30, 320, 1920);
        position: absolute;
        top: -25px;
        right: 20px;
        padding: 0 10px;
        display: inline-block;
        background-color: $colorYellow;
        color: $colorWhite;
    }
    p {
        margin: 0;
        font-size: 14px;
    }

    &-block {

        &--mob-show {
            display: none;

            @media all and (max-width: 1050px) {
                display: block;
            }
        }

        &--mob-hide {

            @media all and (max-width: 1050px) {
                display: none;
            }
        }

        img {
            width: 100%;
        }

        &__title {
            padding: 15px 30px;
            display: block;
            font-size: 10px;
            color: $colorWhite;
            background-color: $colorGreen;
        }

        &__info {
            
            p {
                margin: 0;
            }
        }   
    }
}