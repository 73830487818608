.select {
    @extend %latoBold;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 10px 80px 10px 0;
    border: none;
    background: url("/images/icons/drop-down-arrow.svg") no-repeat;
    background-position: 60% center;
    background-size: 10px 10px;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: $colorYellow;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &::-ms-expand {
        display: none;
    }

    &-block {

        &__select {
            height: 42px;
            padding: 5px 80px 5px 15px;
            background: url("/images/icons/drop-down-arrow.svg") no-repeat;
            background-position: 90% center;
            background-size: 10px 10px;

            @media all and (max-width: 480px) {
                margin-top: 10px;
            }
        }
    }
}