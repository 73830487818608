/**
*
* SASS Variables
*
**/

/* Colors */

$colorMain: #3D3D3D;
$colorGreen: #244722;
$colorWhite: #fff;
$colorSmokyWhite: #F7F7F7;
$colorYellow: #F7931E;
$colorGray: #989898;
$colorNormalGray: #717171;
$colorLightGraySecondary: #C7C7C7;


$colors: (
    'white': $colorWhite,
    'smokyWhite': $colorSmokyWhite,
    'black': $colorMain,
    'green': $colorGreen,
    'yellow': $colorYellow,
    'gray': $colorGray,
    'normalGray': $colorNormalGray,
    'lightGraySecondary':$colorLightGraySecondary,
);

.color {
    @each $colorName, $color in $colors {
        &-#{$colorName} {
            color: $color;
        }
    }
}

$bgColors: (
    'white': $colorWhite,
    'smokyWhite': $colorSmokyWhite,
    'black': $colorMain,
    'green': $colorGreen,
    'yellow': $colorYellow,
    'gray': $colorGray,
    'normalGray': $colorNormalGray,
    'lightGraySecondary':$colorLightGraySecondary,
);

$bgColorsWhiteFont: (
    'black': $colorMain,
    'green': $colorGreen,
    'yellow': $colorYellow,
    'gray': $colorGray,
);


$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);

$socialMediaColors: (twitter: #4099ff, linkedin: #007bb6, facebook: #3b5998, googleplus: #dd4b39, email: #3bb55e, instagram: #e1306c);

// socials
$fb: #3c5b9a;
$insta: #d93175;
$twit: #1da1f2;
$gplus: #DB4437;

$socials: (
    'soc-fb': $fb,
    'soc-insta': $insta,
    'soc-twit': $twit,
    'soc-gplus': $gplus
);