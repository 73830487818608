.flex {
    display: flex;
    width: 100%;
    flex-flow: wrap row;
    align-items: stretch;
    justify-content: flex-start;

    > [class*="col-"] {
        flex-direction: row;
        flex-flow: nowrap row;
        float: none;
    }

    &--auto {
        > * {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }

    &--top {
        align-items: flex-start;

        > [class*="col-"] {
            align-self: flex-start;
        }
    }

    &--bottom {
        align-items: flex-end;
    }

    &__spacer {
        flex-grow: 1;
    }

    &--center {
        justify-content: center;

        > [class*="col-"] {
            align-self: center;
        }
    }

    &--middle {
        align-items: center;
    }

    &--between {
        justify-content: space-between;
    }

    &-header {
        display: flex;

        &:before {

            @media all and (min-width: 768px) {
                content: "";
                display: block;
                flex: 1 1 15%;
                min-width: 250px;
            }
        }

        &__content {

            @media all and (min-width: 768px) {
                flex: 1 1 85%;
            }
        }

        &__col {
            width: 45%;

            @media all and (min-width: 769px) and (max-width: 1250px) {
                width: 60%;
            }

            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }


    &-container {
        position: relative;
        display: flex;

        &--sm-col-direction {
            @media all and (max-width: 1050px) {
                // flex-direction: column;
                display: block;
            }
        }

        &--s-col-direction {
            @media all and (max-width: 1023px) {
                flex-direction: column;
            }
        }

        &__nav {
            padding-bottom: 20px;
            flex: 1 1 15%;
            min-width: 250px;
            transition: all 0.3s ease-in-out;

            @media all and (max-width: 767px) {
                padding-bottom: 0;
                position: absolute;
                // top: 50px;
                left: 0;
                min-width: 220px;
                z-index: 1;
                box-shadow: 8px 10px 10px -7px #989898;
            }

            &--bt {
                top: 85px;
            }

            &-mob {
                transition: all 0.3s ease;

                @media all and (max-width: 767px) {
                    left: -1000px;
                }
            }

            &-btn {
                display: none;
                position: absolute;
                top: -58px;
                // padding: 0;
                fill: $colorGreen;
                background: transparent;
                font-weight: bold;
                transition: all 0.25s ease;

                @media all and (min-width: 481px) and (max-width: 767px) {
                    // top: 15px;
                    display: block;
                    z-index: 1;
                }

                @media all and (max-width: 480px) {
                    // top: 10px;
                    display: block;
                    z-index: 1;
                }

                &:hover {
                    fill: $colorYellow;
                }

                &--yellow {
                    fill: $colorYellow;
                }

                &--bt {
                    top: 50px;
                    padding: 0;
                }
            }
        }
        &__content {
            flex: 1 1 85%;
            overflow-x: auto;

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #F5F5F5;
            }
        
            &::-webkit-scrollbar {
                height: 6px;
                background-color: #F5F5F5;
            }
        
            &::-webkit-scrollbar-thumb {
                background-color: $colorGreen;
                border-radius: 12px;
            }
        }
    }

    &-35per {
        margin: 0 5%;
        flex: 1 1 35%;
        width: 100%;

        @media all and (max-width: 1023px) {
            margin: 0;
            padding: 5%;
        }
    }

    &-40per {
        margin-right: 10%;
        flex: 1 1 40%;
        width: 100%;
    }

    &-50per {
        flex: 1 1 50%;
        width: 100%;
    }

    &-55per {
        flex: 1 1 55%;
        width: 100%;
    }
}