
.progress-table {
    width: 90%;
    text-align: center;

    @media all and (max-width: 1200px) {
        width: 100%;
    }

    @media all and (max-width: 1050px) {
        margin-top: 30px;
        width: 100%;
    }

    &__header {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(14, 16, 320, 1920);
        border-bottom: 1px solid $colorGreen;
        background-color: $colorSmokyWhite;
        color: $colorGreen;

        &-item {
            position: relative;
            padding: 20px 10px 20px;
            min-height: 70px;
            width: 9%;
            text-align: center;

            &:nth-child(n+3):nth-child(-n+9) {

                @media all and (max-width: 1700px) {
                    padding: 5px;
                    width: 3%;
                    min-width: 35px;
                }
            }

            
            &:nth-child(-n+2) {

                &:before {
                    position: absolute;
                    bottom: 0;
                    right: -1px;
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 70%;
                    min-height: 50px;
                    background-color: rgba(112, 112, 112, 0.25);

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        bottom: auto;
                        top: auto;
                        right: 0;
                    }
                }
            }

            &:nth-child(n+9):nth-child(-n+10) {

                &:before {
                    position: absolute;
                    bottom: 0;
                    right: -1px;
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 70%;
                    min-height: 50px;
                    background-color: rgba(112, 112, 112, 0.25);

                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        bottom: auto;
                        top: auto;
                        right: 0;
                    }
                }
            }
        }
    }

    &__row {

        &:nth-child(even) {
            background-color: $colorSmokyWhite;
        }
    }

    &__col {
        padding: 10px;
        font-size: 14px;

        &:nth-child(-n+2) {
            border-right: 1px solid rgba(112, 112, 112, 0.25);
        }

        &:nth-child(n+4) {
            border-left: 1px solid rgba(112, 112, 112, 0.25);
        }

        &:nth-child(3) {
            padding: 10px 0;
        }
    }

    
    &__progress-wrapper {
        flex-basis: 42.85%;
        height: 20px;

        &:first-child {
            margin-left: 7.15%;
            // background-color: $colorYellow;
        }

        &:last-child {
            margin-right: 7.15%;
            // background-color: $colorGreen;
        }
    }

    &__progress-box  {
        display: flex;
    }

    
    &__progress-bar  {
        height: 100%;

        &--left {
            margin-right: 0;
            margin-left: auto;
        }

        &--yellow {
            background-color: $colorYellow;
        }

        &--green {
            background-color: $colorGreen;
        }
    }
}
