/*==========  COLORS  ==========*/
// Color helper classes are next to color definitions in _utils.scss

/*==========  GENERIC  ==========*/
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-upper {text-transform: uppercase;}

.content-center { justify-content: center;}
.content-space-between { justify-content: space-between;}
.content-grow {flex-grow: 1;}
.content-flex { display: flex !important;}
.flex-column {display: flex; flex-direction: column; }
.content-column { flex-direction: column;}
.content-flex--middle { align-items: center};

.relative {position: relative; }

.right {float: right;}
.left {float: left;}

.nowrap {white-space: nowrap;}
.single-word {word-spacing: 9999px; }
.underline {text-decoration: underline; }
.z-index-1 { position: relative; z-index: 1;}
.z-index { position: relative; z-index: 2;}
.inline-block { display: inline-block; }
.mt--minus-50 { margin-top: -50px; }
.lato-font-12 { font-size: 12px;}
.text-vertical-middle {display: flex; align-items: center;}
.flex-col {display: flex; flex-direction: column; align-items: center;}
.flex-end {justify-content: flex-end;}
.flex-wrap {flex-wrap: wrap;}
.flex-nowrap {flex-wrap: nowrap}

@media screen and (min-width: 768px)  { .flex-nowrap-md {flex-wrap: nowrap}}
@media screen and (min-width: 992px) { .flex-nowrap-lg {flex-wrap: nowrap}}
@media screen and (min-width: 1200px) { .flex-nowrap-xl {flex-wrap: nowrap}}

.block-el {display: block;}

.overflow-x-hidden{overflow-x: hidden;}
// .overflow-y-hidden{overflow-y: hidden;}

.icon-width { max-width: 100%;}

.box-sm { max-width: 770px;}

.lato-reg {
    @extend %latoRegular;
}


.color-link-yellow {
    color: $colorYellow;

    &:hover {
        color: $colorGreen;
    }
}

.font-size { 
    &-10 {
        font-size: 10px;
    }

    &-14 {
        font-size: 14px;
    }
}


.section {
    padding: 60px 0;

    &--p-top-small {
        padding-top: 30px;
    }
    &--p-bottom-small {
        padding-bottom: 30px;
    }
    &--p-top-large {
        padding-top: 90px;
    }
    &--p-bottom-large {
        padding-bottom: 90px;
    }
    &--p-top-extra-large {
        padding-top: 120px;
    }
    &--p-bottom-extra-large {
        padding-bottom: 120px;
    }
    &--p-top-none {
        padding-top: 0
    }
    &--p-bottom-none {
        padding-bottom: 0;
    }

    &--m-large {

        &-top  {
            margin-top: 80px;
            padding-top: 0;
        }

        &-bottom  {
            margin-bottom: 80px;
            padding-bottom: 0;
        }
    }
}


$gapSizes: (0: 0, 5: 0.5, 10: 1, 15: 1.5, 20: 2, 25: 2.5, 30: 3, 35: 3.5, 40: 4, 50: 5, 60: 6, 70: 7, 80: 8, 90: 9, 100: 10, 110: 11, 120: 12, 130: 13, 140: 14, 150: 15);

@each $var, $size in $gapSizes {
    $value: #{$size}em;

    /*==========  MARGINS  ==========*/
    .mt--#{$var}em {margin-top: $value}
    .mb--#{$var}em {margin-bottom: $value}
    .ml--#{$var}em {margin-left: $value}
    .mr--#{$var}em {margin-right: $value}

    /*==========  MARGINS BETWEEN SECTIONS ==========*/
    .mt-sec--#{$var}em {
        margin-top: $value;

        @media all and (max-width: 1054px) {
            margin-top: 7em;
        }
    }
    .mb-sec--#{$var}em {
        margin-bottom: $value;

        @media all and (max-width: 1054px) {
            margin-bottom: 7em;
        }
    }

    /*==========  PADDING  ==========*/
    .pt--#{$var}em {padding-top: $value}
    .pb--#{$var}em {padding-bottom: $value}
    .pl--#{$var}em {padding-left: $value}
    .pr--#{$var}em {padding-right: $value}

    .pall--#{$var}em {padding: $value}
}

.mt-md-20 {
    @media all and (max-width: 1200px ) {
        margin-top: 2em;
    }
}

.mt-sm-20 {
    @media all and (max-width: 992px ) {
        margin-top: 2em;
    }
}

.mt-xs-20 {
    @media all and (max-width: 767px ) {
        margin-top: 2em;
    }
}

.mb-m-25 {
    margin-bottom: -0.35em;

    @media all and (max-width: 767px) {
        margin-bottom: -0.4em;
    }
}

.block-padded {
    padding: 1em 1em 1.5em;
}

.padded {

    &-lg {
        padding: 2.5em 12em;

        @media all and (min-width: 6011px) and (max-width: 768px) {
            padding: 2.5em 7em;
        }

        @media all and (max-width: 600px){
            padding: 2.5em 3em;
        }
    }
}


/*========= RWD HELPERS =========*/
@media screen and (min-width: 200px) and (max-width: 568px) {
    .hide-on-mobile {
        display: none;
    }
}

@media screen and (min-width: 569px) and (max-width: 767px) {
    .hide-on-sm,
    .hide-on-sm-down {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .hide-on-md,
    .hide-on-md-down {
        display: none;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .hide-on-lg,
    .hide-on-lg-down {
        display: none;
    }
}

/*========= HIDING =========*/

/*
 * Hide visually and from screen readers:
 */
.hidden {display: none !important;}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {visibility: hidden;}

.mt-sm-auto {

    @media all and (max-width: 1200px) {
        margin-top: auto;
    }
}

.pointed {

    &__item {
        margin: 5px;
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $colorYellow;
    }
}
