.bg {
    @each $colorName, $color in $colors {
        &-#{$colorName} {
            background-color: $color;
            color: $colorMain;

            @if map-has-key($bgColorsWhiteFont, $colorName) {
                color: $colorWhite;

                // .content ul li:before,
                // &.content ul li:before {
                //     background-image: url(../images/list-white-arrow.svg);
                // }
            }
        }

        &-transparent-#{$colorName} {
            background-color: rgba($color, 0.8);
        }
    }

    &-circle {

        &:before {
            position: absolute;
            top: -5%;
            left: 50%;
            margin-left: -220px;
            display: block;
            width: 440px;
            height: 440px;
            content: "";
            transition: all 0.25s ease;
            z-index: -1;
    
            background-image: url(../images/Sun_circle.svg);
    
            @media all and (max-width: 1150px) {
                margin-left: -125px;
                top: -10%;
                width: 250px;
                height: 250px;
            }
        }

        &--bigger-top-space {
            
            @media all and (max-width: 767px) {
                padding-top: 20px;
            }

            &:before {
                top: -2%;

                @media all and (max-width: 767px) {
                    top: -80px;
                }
            }
        }

        &--middle-top-space {
            &:before {
                top: -14%;

                @media all and (min-width: 1151px) and (max-width: 1200px) {
                    top: -18%;
                }

                @media all and (max-width: 1150px) {
                    top: -10%;
                }
            }
        }

        &--smaller-top-space {
            
            @media all and (max-width: 1150px) {
                padding-top: 100px;
            }

            &:before {
                top: -18%;

                @media all and (max-width: 1150px) {
                    top: -12%;
                }
            }
        }

        &--z-index-0 {
            &:before {
                z-index: 0;
            }
        }

        &--right {

            &:before {
                right: 30%;
                left: auto;
                z-index: 0;

                @media all and (max-width: 1150px) {
                    right: auto;
                    left: 50%;
                }
            }
        }
    }

    &-perpendicular-line {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -1px;
            top: 0;
            height: 100%;
            width: 4px;
            background: $colorYellow;
            z-index: -1;

            @media all and (max-width: 767px) {
                display: none;
            }
        }
    }

    &-img-gray {
        background-image: url(../images/backgrounds/bg-white-smoke-2.png);
        background-size: 100% 80%;

        @media all and (max-width: 767px) {
            background-image: none;
        }
    }
    
    &-right {
        position: relative;
        background-image: url(../images/about-header.png);
        background-color: #DCDCDF;
        background-position: top right;
        background-clip: content-box;
        background-origin: content-box;
        background-repeat: no-repeat;
        z-index: -10;

        @media all and (max-width: 1999px) {
            background-size: cover;
            background-position: top left;
        }
    }

    &-left {
        background-image: url(../images/science.png);
        // background-color: #DCDCDF;
        background-position: top;
        background-clip: content-box;
        background-origin: content-box;
        background-repeat: no-repeat;
    }

    &-with-top-line {
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: 55%;
            position: absolute;
            top: 150px;
            display: block;
            background: $colorSmokyWhite;
            z-index: -1;

            @media all and (min-width: 768px) and (max-width: 1100px) {
                height: 60%;
            }

            @media all and (max-width: 767px) {
                display: none;
            }
        }

        // &:after {
        //     content: '';
        //     width: 100%;
        //     height: 2px;
        //     position: absolute;
        //     top: 150px;
        //     display: block;
        //     background: $colorGreen;
        //     @media all and (max-width: 767px) {
        //         display: none;
        //     }
        // }
    }
}


