/**
*
* Grid
*
**/

$columnsNumbers: (1,2,3,4,5,6,7,8,9,10,11,12);
$columnsNames: xs, sm, md, lg;
$iteration: (1/12);
$tmp: '';

$gutters: 10, 20, 30, 40, 50, 60, 70, 80;

// Row with default 15px gutter
$gutter: 15px;
.row {
  margin-left: 0;
  margin-right: 0;

  > [class*="col-"] {
    padding: 0;
  }
}

@each $gutter in $gutters {
    .row-g-#{$gutter;} {
        margin-left: (-$gutter)+px;
        margin-right: (-$gutter)+px;

        @if $gutter > 50 {
          @media all and (max-width: 1180px) {
            margin-left: (-$gutter/3)+px;
            margin-right: (-$gutter/3)+px;
          }
        }


        //depends on styleguide
        //padding-bottom: ($gutter)+px;

        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 0;
        }

        > [class*="col-"] {
          padding-left: ($gutter)+px;
          padding-right: ($gutter)+px;
          padding-bottom: 20px;
          //padding-bottom: ($gutter)+px;

          @if $gutter > 50 {
            @media all and (max-width: 1180px) {
              padding-left: ($gutter/3)+px;
              padding-right: ($gutter/3)+px;
            }
          }

          .pos-center-v--right {
            padding-right: ($gutter)+px;
          }

          @media (max-width: 767px) {
              padding-left: 0;
              padding-right: 0;
              padding-bottom: ($gutter)+px;

              .pos-center-v--right {
                padding-right: 0;
              }
          }

          @media screen and (min-width: 200px) and (max-width: 767px) {
              &:last-child {
                  padding-bottom: 0;
              }
          }

        }

        + .row-g-#{$gutter;} {
          margin-top: ($gutter * 2)+px;

          @media (max-width: 767px) {
            margin-top: ($gutter)+px;
          }

        }

        $tmp: '+ '+(4*$gutter)+px;

        &.table-view {
          width: calc(100% #{$tmp});
          margin-left: (-$gutter)+px;
          margin-right: (-$gutter)+px;

          @media all and (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }


        + .row-g-#{$gutter;} {
          padding-top: ($gutter * 2)+px;

          @media screen and (min-width: 200px) and (max-width: 767px) {
            padding-top: ($gutter)+px;
          }
        }
    }
}



.row-vertical-middle,
.row-vertical-top,
.row-vertical-bottom {
    @media all and (min-width: 768px) {
        display: flex;
        flex-flow: nowrap row;
        justify-content: flex-start;
        align-items: stretch;

        > [class*="col-"] {
            flex-direction: column;
            flex-flow: wrap column;
            float: none;
        }
    }
}

.row-equal-col-height {
    @media all and (min-width: 768px) {
        display: flex;
        flex-flow: wrap row;
        // justify-content: center;
        align-items: stretch;

        > [class*="col-"] {
            flex-direction: column;
            flex-flow: wrap column;
            float: none;

            @supports (-moz-appearance:none) and (text-emphasis:none) {
                margin-bottom: 2.5vw;
            }
        }
    }
}


.row-vertical-middle {
    // justify-content: center;

    > [class*="col-"] {
        align-self: center;
        //padding-bottom: 0;

        @media all and (max-width: 768px) {
            //padding-bottom: 3rem;
        }

        &:last-child {
            @media all and (max-width: 768px) {
                padding-bottom: 0;
            }
        }

    }
}

.row-vertical-top {
    justify-content: flex-start;

    > [class*="col-"] {
        align-self: flex-start;
    }
}

.row-vertical-bottom {
    justify-content: flex-end;

    > [class*="col-"] {
        align-self: flex-end;
    }
}

.row-auto-width {
    display: flex;

    > [class*="col-"] {
        flex: 0 0 auto;
    }
}







// border on a row (:after fix is used because when whole website is in one container)
// border style to change of course or to comment when it is not needed

.container,
.container--narrow {
  > .row {
    &:after {
      display: block !important;
      margin-left: $gutter;
      margin-right: $gutter;
    }
  }
}

/*
@each $gutter in $gutters {
  .container,
  .container--narrow {
    > .row-g-#{$gutter;} {
      &:after {
        display: block !important;
        margin-left: ($gutter)+px;
        margin-right: ($gutter)+px;
      }
    }
  }
}
*/

.row--5cols {
  > [class$="-7"] {
    width: 60%;
    padding-right: 0;

    @media (max-width: 767px) {
      width: 100%;
      padding-right: 30px; //depends on row gutter
    }

    > [class$="-4"] {
      padding-left: inherit;
      padding-right: inherit;

      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }

      &:first-child {
        margin-left: -30px;  //depends on row gutter
      }
    }
  }

  > [class$="-5"] {
    width: 40%;
    padding-left: 0;

    @media (max-width: 767px) {
      width: 100%;
      padding-left: 30px; //depends on row gutter
    }

    > [class$="-6"] {
      padding-left: inherit;
      padding-right: inherit;

      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }

      &:last-child {
        margin-right: -30px; //depends on row gutter
      }
    }
  }
}


// every column default settings
%default-col-style {
  position: relative;
  min-height: 1px;
}

//generate class list comma separated with default settings - example: .class-1, .class-2 etc...
@mixin col-name-number-list {
  @each $name in $columnsNames {
    @for $i from 1 through length($columnsNumbers) {
      .col-#{$name}-#{$i} { @extend %default-col-style; }
    }
    .col-#{$name}-five { @extend %default-col-style; }
  }
}
@include col-name-number-list;



// /////////////////
//  COL VXS STYLE  //
// /////////////////

@media (max-width: 400px) {

  @for $j from 1 through length($columnsNumbers) {
    .col-vxs-#{$j} { width: 100% !important; }
  }
}


// /////////////////
//  COL XS STYLE  //
// /////////////////

// every column float setting
%default-float-style {
  float: left;
}

@mixin col-xs-float-list {
  @for $j from 1 through length($columnsNumbers) {
    .col-xs-#{$j} { float: left; }
  }
  .col-xs-five { float: left; }
}
@include col-xs-float-list;


@each $number in $columnsNumbers {
  .col-xs-#{$number} {
      width: percentage($iteration * $number);
  }

  .col-xs-pull-#{$number} {
      right: percentage($iteration * $number);
  }

  .col-xs-push-#{$number} {
      left: percentage($iteration * $number);
  }

  .col-xs-offset-#{$number} {
      margin-left: percentage($iteration * $number);
  }
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-five {
  width: 20%;
}

.col-xs-pull-five {
  right: 20%;
}

.col-xs-push-five {
  left: 20%;
}

.col-xs-offset-five {
  margin-left: 20%;
}

// /////////////////
//  COL SM STYLE  //
// /////////////////
@mixin col-sm-float-list {
  @for $i from 1 through length($columnsNumbers) {
    .col-sm-#{$i} { float: left; }
  }
  .col-sm-five { float: left; }
}

@media (min-width: 768px) {

  @include col-sm-float-list;

  @each $number in $columnsNumbers {
    .col-sm-#{$number} {
        //width: percentage($iteration * $number);
        width: percentage($number/12);
    }

    .col-sm-pull-#{$number} {
        right: percentage($iteration * $number);
    }

    .col-sm-push-#{$number} {
        left: percentage($iteration * $number);
    }

    .col-sm-offset-#{$number} {
        margin-left: percentage($iteration * $number);
    }
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-five {
    width: 20%;
  }

  .col-sm-pull-five {
    right: 20%;
  }

  .col-sm-push-five {
    left: 20%;
  }

  .col-sm-offset-five {
    margin-left: 20%;
  }
}

// /////////////////
//  COL MD STYLE  //
// /////////////////

@mixin col-md-float-list {
  @for $i from 1 through length($columnsNumbers) {
    .col-md-#{$i} { float: left; }
  }
  .col-md-five { float: left; }
}

@media (min-width: 992px) {
  @include col-md-float-list;

  @each $number in $columnsNumbers {
    .col-md-#{$number} {
        width: percentage($iteration * $number);
    }

    .col-md-pull-#{$number} {
        right: percentage($iteration * $number);
    }

    .col-md-push-#{$number} {
        left: percentage($iteration * $number);
    }

    .col-md-offset-#{$number} {
        margin-left: percentage($iteration * $number);
    }
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-five {
    width: 20%;
  }

  .col-md-pull-five {
    right: 20%;
  }

  .col-md-push-five {
    left: 20%;
  }

  .col-md-offset-five {
    margin-left: 20%;
  }
}

// /////////////////
//  COL LG STYLE  //
// /////////////////

@mixin col-lg-float-list {
  @for $i from 1 through length($columnsNumbers) {
    .col-lg-#{$i} { float: left; }
  }
  .col-lg-five { float: left; }
}

@media (min-width: 1200px) {
  @include col-lg-float-list;

  @each $number in $columnsNumbers {
    .col-lg-#{$number} {
        width: percentage($iteration * $number);
    }

    .col-lg-pull-#{$number} {
        right: percentage($iteration * $number);
    }

    .col-lg-push-#{$number} {
        left: percentage($iteration * $number);
    }

    .col-lg-offset-#{$number} {
        margin-left: percentage($iteration * $number);
    }
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-five {
    width: 20%;
  }

  .col-lg-pull-five {
    right: 20%;
  }

  .col-lg-push-five {
    left: 20%;
  }

  .col-lg-offset-five {
    margin-left: 20%;
  }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
    content: " ";
    display: table;
}


@each $gutter in $gutters {
  
	.row-g-#{$gutter;}:before,
	.row-g-#{$gutter;}:after {
		content: " ";
		display: table;

		@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
			display: block;
		}
	}
}


.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
    clear: both;
}

@each $gutter in $gutters {
  .row-g-#{$gutter;}:after {
    clear: both;
  }
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}

.invisible {
    visibility: hidden;
}
.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}
.hidden {
    display: none !important;
    visibility: hidden !important;
}
.affix {
    position: fixed;
}
@-ms-viewport {
    width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 400px) {
    .visible-vxs {
        display: block !important;
    }
    table.visible-vxs {
        display: table;
    }
    tr.visible-vxs {
        display: table-row !important;
    }
    th.visible-vxs,
    td.visible-vxs {
        display: table-cell !important;
    }

    .visible-vxs-block {
        display: block !important;
    }

    .visible-vxs-inline {
        display: inline !important;
    }

    .visible-vxs-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table;
    }
    tr.visible-xs {
        display: table-row !important;
    }
    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }

    .visible-xs-block {
        display: block !important;
    }

    .visible-xs-inline {
        display: inline !important;
    }

    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
    table.visible-sm {
        display: table;
    }
    tr.visible-sm {
        display: table-row !important;
    }
    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }

    .visible-sm-block {
        display: block !important;
    }

    .visible-sm-inline {
        display: inline !important;
    }

    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
    table.visible-md {
        display: table;
    }
    tr.visible-md {
        display: table-row !important;
    }
    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }

    .visible-md-block {
        display: block !important;
    }

    .visible-md-inline {
        display: inline !important;
    }

    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
    table.visible-lg {
        display: table;
    }
    tr.visible-lg {
        display: table-row !important;
    }
    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }

    .visible-lg-block {
        display: block !important;
    }

    .visible-lg-inline {
        display: inline !important;
    }

    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}
.visible-print {
    display: none !important;
}
@media print {
    .visible-print {
        display: block !important;
    }
    table.visible-print {
        display: table;
    }
    tr.visible-print {
        display: table-row !important;
    }
    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}
.visible-print-block {
    display: none !important;
}
@media print {
    .visible-print-block {
        display: block !important;
    }
}
.visible-print-inline {
    display: none !important;
}
@media print {
    .visible-print-inline {
        display: inline !important;
    }
}
.visible-print-inline-block {
    display: none !important;
}
@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}
@media print {
    .hidden-print {
        display: none !important;
    }
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}


.col-xs-12.text-right {
    @media (max-width: 767px) {
        text-align: left;
    }
}



.col-pad {
    padding: calc(100% / 12) !important;
    // padding-top: ($gutter/2)+vw !important;
    // padding-bottom: ($gutter/2)+vw !important;
}