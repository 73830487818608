.search {
    padding: 30px 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    // @media all and (max-width: 991px) { //
    //     flex-direction: column;
    // }

    &__container {
        display: flex;
        align-items: center;

        @media all and (min-width: 768px) and (max-width: 991px) {
            align-self: flex-start;
        }

        @media all and (max-width: 767px) {
            margin-bottom: 10px;
            width: 100%;
            flex-direction: column;
            align-self: flex-start;
            align-items: stretch;
        }
    }

    &__item {
        margin-bottom: 15px;

        @media all and (max-width: 600px) {
            margin-bottom: 10px;
            width: 100%;
        }
        
        select {
            width: auto;
        }
    }

    &__btn {
        margin-bottom: 15px;
        margin-left: auto;

        // @media all and (max-width: 991px) { //
        //     margin: 20px 0 0;
        //     align-self: flex-start;
        // }

        @media all and (max-width: 767px) {
            margin-left: 0;
            align-self: flex-start;
        }
    }
}