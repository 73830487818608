.list-image-block {
    margin-top: -3em;
    padding: 5em 0 2em;
    position: relative;
    display: flex;  

    &-lower {
        margin-top: 10em;

        @media all and (max-width: 1200px) {
            margin-top: 3em;
        }
    }

    &-title {
        margin-left: 2em;

        @media all and (max-width: 767px) {
            position: relative;
            margin-left: 1em;
            z-index: 1;
        }
    }

    @media all and (max-width: 767px) {
        margin-top: -40px;
        padding: 15px;
        flex-direction: column;
        background: $colorSmokyWhite;
    }

    &:before {
        position: absolute;
        top: 0;
        width: 90%;
        height: 100%;
        display: block;
        content: "";
        background: $colorSmokyWhite;
        z-index: -1;

        @media all and (max-width: 767px) {
            display: none;
        }
    }

    &__list {
        margin-left: 3.5em;
        margin-right: 1.5em;
        position: relative;
        flex: 1 0 50%;

        @media all and (max-width: 767px) {
            margin: 1.5em 0 0;
            order: 2;
        }
    }

    &__link {
        position: absolute;
        bottom: -50px;

        @media all and (max-width: 767px) {
            margin-left: 10px;
            position: relative;
            bottom: -35px;
        }
    }

    &__image {
        position: relative;
        flex: 1 1 50%;

        @media all and (max-width: 767px) {
            display: flex;
            justify-content: center;
            order: 1;
        }

        img {
            @media all and (max-width: 767px) {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__label {
        position: absolute;
        top: 30px;
        left: -30px;
        flex: 1 0 50%;
        font-size: 30px;

        @media all and (max-width: 767px) {
            top: auto;
            bottom: -15px;
            right: 0.5em;
            left: auto;
        }
    }

    p {
        margin: 0;
    }
}