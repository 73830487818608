.small-table {
    width: 100%;
    font-size: 14px;
    table-layout: fixed;
    border-collapse: collapse;

    &-wrapper {
        overflow-x: auto;

        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #F5F5F5;
        }
    
        &::-webkit-scrollbar {
            height: 6px;
            background-color: #F5F5F5;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: $colorGreen;
            border-radius: 12px;
        }
    }

    &__header {
        @extend %playfairDisplayBold;

        color: $colorWhite;

        &--yellow {

            .small-table__header-item {
                background-color: $colorYellow;
            }
        }

        &-row {
            display: flex;
        }


        &-item {
            position: relative;
            padding: 10px;
            // width: 100%;
            // min-width: 55px;

            flex-grow: 1;
            min-width: 55px;
            width: 55px;
            min-height: 62px;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: $colorGreen;

            &:not(:last-child) {

                &:before {
                    position: absolute;
                    bottom: 0;
                    right: -1px;
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 70%;
                    background-color: rgba(112, 112, 112, 0.25);
                    z-index: 1;
                }
            }
        }
    }

    &__body {
        text-align: center;

        &-row {
            display: flex;
        }

        &-item {
            position: relative;
            padding: 20px 10px;

            flex-grow: 1;
            min-width: 55px;
            width: 55px;
            min-height: 62px;

            display: flex;
            justify-content: center;
            align-items: center;

            &:not(:last-child) {
                &:before {
                    position: absolute;
                    bottom: 0;
                    right: -1px;
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 100%;
                    background-color: rgba(112, 112, 112, 0.25);
                }
            }
        }
    }
}