/**
*
* Headlines
*
**/

.headline {
    margin: 0 0 0.25em 0;

    &-1 {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(30, 80, 320, 1920);
        @include dynamicLineHeight(40, 90, 320, 1920);
    }

    &-2 {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(25, 60, 320, 1920);
        @include dynamicLineHeight(33, 70, 320, 1920);
    }

    &-3 {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(25, 40, 320, 1920);
        @include dynamicLineHeight( 40, 70, 320, 1920);
    }

    &-4 {
        @extend %latoBold;
        @include dynamicFontSize(26, 36, 320, 1920);
    }

    &-5 {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(24, 30, 320, 1920);
    }

    &-6 {
        @extend %latoBold;
        @include dynamicFontSize(24, 30, 320, 1920);
    }

    &-7 {
        @extend %latoBold;
        @include dynamicFontSize(20, 26, 320, 1920);
        @include dynamicLineHeight( 24, 32, 320, 1920);
    }

    &-8 {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(18, 24, 320, 1920);
    }

    &-9 {
        @extend %latoBold;
        @include dynamicFontSize(18, 22, 320, 1920);
        @include dynamicLineHeight(23, 27, 320, 1920);
    }

    &-10 {
        @extend %latoBold;
        @include dynamicFontSize(16, 18, 320, 1920);
    }

    &-11 {
        @extend %latoRegular;
        @include dynamicFontSize(18, 26, 320, 1920);
    }

    &-12 {
        @extend %latoLight;
        @include dynamicFontSize(18, 26, 320, 1920);
        @include dynamicLineHeight( 20, 30, 320, 1920);
    }

    &-13 {
        @extend %latoBold;
        @include dynamicFontSize(22, 60, 320, 1920);
        @include dynamicLineHeight(36, 66, 320, 1920);
    }

    &-14 {
        @extend %latoRegular;
        @include dynamicFontSize(18, 22, 320, 1920);
        @include dynamicLineHeight(23, 27, 320, 1920);
    }

    &-15 {
        @extend %latoBold;
        @include dynamicFontSize(18, 40, 320, 1920);
        line-height: 1.4;
    }

    &--bg {
        margin-bottom: 15px;
        display: inline-block;
    }

    &--padd-sm {
        padding: 0 35px 10px;
    }

    &--padd-md {
        padding: 10px 40px 15px;
    }

    &--padd-lg {
        padding: 10px 70px 20px;

        @media all and (max-width: 677px) {
            padding: 10px 20px;
        }
    }
}