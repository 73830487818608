.product-table {
    display: flex;
    flex-wrap: wrap;

    &__col {
        margin-bottom: 1.5em;

        display: flex;
        flex-direction: column;

        &:not(:first-child) {
            position: relative;

            &:before {
                position: absolute;
                top: 15px;
                display: block;
                height: 80%;
                width: 1px;
                content: "";
                background-color: rgba(112, 112, 112, 0.25);
            }
        }
    }

    &__item {
        min-height: 70px;
        min-width: 59px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-grow: 1;
        font-size: 14px;

        &:first-child {
            @extend %playfairDisplayBold;
            align-self: stretch;
            flex-basis: 100%;
            border-bottom: 1px solid $colorGreen;
            word-break: break-word; 

            background-color: $colorGreen; // $colorSmokyWhite
            color: $colorWhite;
        }
        
        &:nth-child(2) {
            color: $colorNormalGray;
        }
    }
}