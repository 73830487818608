.flag {
    @include dynamicFontSize(16, 18, 320, 1920);
    @include dynamicLineHeight( 20, 26, 320, 1920);
    margin: 1em;
    padding: 2em 6em;
    max-width: 470px;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    text-align: center;
    background-color: $colorWhite;
    color: $colorGreen;

    border: 4px solid $colorGreen;

    @media all and (max-width: 1000px) {
        padding: 2em 3em;
    }

    &__img {
        align-self: center;
    }

    &-box {
        padding-bottom: 2em;

        background-image: url('/images/placeholders/yellow-border-box.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50% 100%;

        @media all and (max-width: 768px) {
            background-size: 100% 100%;
        }

        &__content {
            display: flex;
            justify-content: space-around;

            @media all and (max-width: 768px) {
                flex-wrap: wrap;
            }
        }

        &__headline {
            padding-top: 1em;
        }
    }
}