/**
*
* Main footer
*
**/

.l-footer {
    margin-top: auto;
    padding: 2.5em 0 2em 0;

    &__logo {
        display: inline-block;
        width: 175px;

        @media all and (max-width: 420px) {
            width: 130px;
        }
    }

    &__link {
        color: $colorMain;
        text-decoration: underline;
        transition: color 0.3s;

        &:hover {
            color: $colorYellow;
        }
    }

    &__headline {
        @extend %latoBold;
        @include dynamicFontSize(16, 18, 320, 1920);
        padding: 10px;
        color: $colorWhite;
        background-color: $colorGreen;
    }

    &__container {
        display: flex;
        justify-content: space-around;


        @media all and (max-width: 1023px) {
            flex-direction: column;
            align-items: center;
        }
    }

    p {
        margin: 0;
    } 

    &__item {
        @media all and (max-width: 1023px) {
            margin-bottom: 40px;
            text-align: center;
        }

        &:first-child {
            max-width: 250px;
        }

        &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            @media all and (max-width: 1023px) {
                align-items: center;
            }
        }

        &-bp {
            transition: all 0.25s;

            &:hover {
                .l-footer__bp {
                    opacity: 1;
                }

                svg {
                    opacity: 0.7;
                }
            }

            svg {
                color: $colorMain;
                opacity: 0.5;
                transition: all 0.25s;
            }
        }
    }

    &__contact {
        &-item {
            padding: 5px 0;
            transition: all 0.25s;

            &:hover {
                svg {
                    fill: $colorYellow;
                }
            }
        }
        svg {
            fill: $colorGreen;
            transition: all 0.25s;
        }
    }

    &__bp {
        color: $colorMain;
        opacity: 0.5;
        transition: all 0.25s;
    }
}