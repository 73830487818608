.people-data {
    margin: -10% auto 0;
    padding: 20px 50px 30px;
    position: relative;
    width: 70%;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    z-index: 1;

    @media all and (min-width: 768px) and (max-width: 1100px) {
        padding: 20px 30px 30px;
        width: 95%;
    }

    @media all and (min-width: 376px) and (max-width: 767px) {
        padding: 20px 30px 30px;
        width: 80%;
    }

    @media all and (max-width: 375px) {
        padding: 20px 20px 30px;
        width: 95%;
    }

    &-block {
        padding: 2em;
    }

    &--full-width {
        margin: 0;
        padding: 20px 20px 10px;
        width: 100%;
    }

    &__underline {
        margin-top: auto;
        position: relative;
        margin-bottom: 0.5em;

        &:before {
            position: absolute;
            top: 0;
            display: block;
            width: 100%;
            height: 2px;
            content: "";
            background-color: $colorYellow;
        }

        &--large {
            @extend %latoLight;
            @include dynamicFontSize(16, 26, 320, 1920);
            padding-bottom: 0.9em;
            line-height: 1.4;
        }

        &--middle {

            @extend %latoLight;
            @include dynamicFontSize(16, 20, 320, 1920);
        }
    }

    &__contact {
        list-style-type: none; 

        &-item {
            padding: 5px 0;
        }
    }
}