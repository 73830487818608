/**
*
* Top navigation
*
**/

.l-navbar {
    position: relative;
    display: flex;
    padding: 35px;
    background-color: $colorWhite;
    z-index: 1;
    transition: all 0.25s ease;
    box-shadow: 0 2px 1px #f2f2f2;
    background-color: #fff;
    z-index: 10;

    @media all and (min-width: 1170px) and (max-width: 1450px) {
        padding: 35px 20px;
    }

    @media all and (min-width: 421px) and (max-width: 1150px) {
        margin: 0;
        padding: 15px;
        z-index: 5;
    }

    @media all and (max-width: 420px) {
        margin: 0;
        padding: 15px;
        z-index: 5;
    }

    &--animation-delay {
        animation: fadeInDownNavbar 1s linear;
    }

    &__dropdown {
        position: absolute;
        padding: 10px;
        width: 170px;
        top: 60px;
        box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
        background: $colorWhite;
        right: 5px;
        display: none;

        &:before {
            position: absolute;
            top: -16px;
            right: 0;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border-right: 15px solid transparent;
            border-bottom: 17px solid #fff;
            border-left: 15px solid transparent;
            z-index: 2;
        }

        &:after {
            position: absolute;
            top: -25px;
            right: -4px;
            display: block;
            width: 0;
            height: 0;
            content: "";
            border-right: 19px solid transparent;
            border-bottom: 20px solid rgba(0,0,0,.1);
            border-left: 19px solid transparent;
            z-index: 1;
            -webkit-filter: blur(2px);
            filter: blur(2px);
        }
        
        &-icon {
            background: transparent;
        }

        &-item {
            padding: 5px;
            transition: all 0.25s;

            &:hover {
                color: $colorYellow;
            }
        }

        &-btn {
            padding: 0;
            background: transparent;
        }
    }

    &--fixed {
        position: fixed;
        width: 100%;
    }


    &__logo {
        margin-right: 85px;
        display: flex;
        flex: 1 1 10%;
        // flex-shrink: 0;

        @media all and (min-width: 321px) and (max-width: 1300px) {
            margin: 0;
            position: relative;
            z-index: 10;
        }

        @media all and (max-width: 320px) {
            margin: 0;
            position: relative;
            z-index: 10;
            width: 105px;
        }
    }

    &__logo-img {
        width: 175px;
        height: 100%;

        @media all and (max-width: 420px) {
            width: 130px;
        }
    }

    &__menu {
        display: flex;
        align-items: center;

        @media all and (min-width: 421px) and (max-width: 1169px) {
            display: none;
            padding: 30px;
            position: absolute;
            top: 64px;//88
            left: 0;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            background-color: $colorSmokyWhite;
            box-shadow: 0 2px 13px #d9d9d9;
        }

        @media all and (max-width: 420px) {
            display: none;
            padding: 30px 10px;
            position: absolute;
            top: 55px;
            left: 0;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            background-color: $colorSmokyWhite;
            box-shadow: 0 2px 13px #d9d9d9;
        }

        &-icons {
            display: none;

            @media all and (max-width: 576px) {
                display: flex;
                position: absolute;
                bottom: 40px;
                right: 25px;
            }
        }

        &-icon {
            margin: 0 10px;
    
            svg {
                fill: $colorGreen;
                transition: all 0.25s;
    
                &:hover {
                    fill: $colorYellow;
                }
            }
        }
    }

    &__menu-link-btn {
        position: relative;

        @media all and (min-width: 1150px) {
            display: none;
        }

        &__link {
            position: absolute;
            left: 10px;
            top: 5px;
        }
    }

    &__menu-link {
        @extend %latoBold;
        position: relative;
        margin: 0 11px;
        @include dynamicFontSize(16, 18, 320, 1920);

        @media all and (min-width: 1170px) and (max-width: 1450px) {
            margin: 0 8px;
            font-size: 15px;
        }

        @media all and (max-width: 1150px) {
            margin-bottom: 12px;
        }
        

        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3px;
            width: 0;
            content: "";
            background-color: $colorYellow;
            transition: width .3s;
        }

        &--active {
            &:after {
                width: 100%;
            }
        }

        &:hover {
            &:after {
                width: 100%;
            }
        }
    }

    &__right {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
    }

    &__right-link {
        margin: 0 12px;
        position: relative; 

        @media all and (max-width: 767px) {//22.10
            position: static;
        }

        @media all and (max-width: 576px) {
            margin: 0 5px;
        }
        
        &-btn {
            @media all and (max-width: 1150px) {
                display: none;
            }
        }

        &--mob-hide {
            @media all and (max-width: 576px) {
                display: none;
            }
        }

        svg {
            fill: $colorGreen;
            transition: all 0.25s;

            &:hover {
                fill: $colorYellow;
            }
        }
    }

    &__humburger {
        i {
            display: block;
            width: 24px;
            height: 3px;
            background: $colorYellow;
        }
    
        i:nth-child(2) {
            margin-top: 4px;
            opacity: 1;
        }
    
        i:nth-child(3) {
            margin-top: 4px;
        }
    }

    &__open {
        display: block;
        cursor: pointer;
        opacity: 0;

        @media all and (max-width: 1169px) {
            margin-left: 10px;
            align-self: center;
            order: 3;
            opacity: 1;
        }
    }
}


#nav:checked {

    & + .l-navbar__humburger {
        transform: rotate(45deg);
    
        i {
            transition: transform .2s ease;
    
            &:nth-child(1) {
                transform: translateY(6px) rotate(180deg);
            }
    
            &:nth-child(2) {
                opacity: 0;
            }
    
            &:nth-child(3) {
                transform: translateY(-8px) rotate(90deg);
            }
        }
    }

    & ~ .l-navbar__menu {
        display: flex;

        .l-navbar__menu-link-btn {
            display: block;
            
            @media all and (min-width: 1150px) {
                display: none;
            }
        }
    }
}

@keyframes fadeInDownNavbar {
    from {
      opacity: 1;
      transform: translate3d(0, -100%, 0);
    }

    // 80% {
    //     opacity: 0;
    //     transform: translate3d(0, -50%, 0);
    // }
  
    to {
      opacity: 1;
      transform: none;
    }
}