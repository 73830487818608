.quote-block {
    display: flex;
    flex-wrap: wrap;

    &__text {
        padding: 0 1em;
        flex-basis: 80%;
    }

    &__icon {
        flex-basis: 10%;

        &:nth-of-type(1) {
            align-self: flex-start;
        }
        &:nth-of-type(2) {
            align-self: flex-end;
        }
    }

    &__footer {
        margin-right: 15%;
        text-align: right;
        flex-grow: 1;
    }
}