.img-gallery {
    display: block;

    &__figure {
        position: relative;

        &-label {
            @extend %latoBold;
            @include dynamicFontSize(16, 30, 320, 1920);
            position: absolute;
            left: 6%;
            padding: 4% 3% 2%; 
            background-color: $colorYellow;     
            color: $colorWhite;
            text-transform: uppercase;
        }
    }

    &__list {
        display: block;
        margin-top: 0.5rem;

        &-item {
            display: inline-block;
            margin: 0.5rem 0;
            border: 3px solid transparent;

            &.active {
                border: 3px solid $colorYellow;
            }

            &:not(:last-child) {
                margin-right:  0.5rem;

                @media all and (max-width: 1200px) {
                    margin-right:  0.3rem;
                }
            }
        }

        &-thumb {
            display: block;
            width: 115px;
            height: 80px;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            cursor: pointer;

            @media all and(max-width: 1200px) {
                width: 80px; //95
                height: 55px; //60
            }
        }
    }
}
