.map-box {

    @media all and (max-width: 767px) {
        position: relative;
        padding: 20px 20px 40px;
        background-color: $colorSmokyWhite;
    }

    &__content {
        position: relative;

        @media all and (max-width: 767px){
            position: static;
        }
    }

    &__headline {
        margin-bottom: 1em;

        @media all and (max-width: 767px){
            margin-bottom: 0;
        }
    }

    &__image-box {
        position: relative;
        width: 100%;
        height: 100%;

        @media all and (max-width: 767px) {
            text-align: right;
        }
    }

    &__image {
        width: 580;
        max-width: 580px;
        height: 685px;
        max-height: 685px;
        position: absolute;
        top: -100px;

        @media all and (min-width: 768px) and (max-width: 1024px) {
            height: 600px;
            max-height: 600px;
        }

        @media all and (max-width: 767px) {
            top: auto;
            position: relative;
            height: auto;
            width: 100%;
        }
    }

    &__link {
        position: absolute;
    }

    &__btn {
        position: absolute;

        @media all and (max-width: 767px) {
            bottom: -25PX;
        }
        
    }
}