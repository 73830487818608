
.orders {
    display: block;
    margin-bottom: 1rem;
    padding: 0.5rem;

    &__row {
        display: table;
        width: 100%;
        padding: 0.25rem 0;

        @media all and (min-width: 200px) and (max-width: 568px) {
            display: block;
            margin: 0.5rem 0;
            padding: 0.5rem 0;
            border-bottom: 1px solid rgba($colorMain, 0.2);
        }
    }

    &__order {
        width: auto;
    }

    &__date {
        width: 19%;
    }

    &__status {
        width: 20%;
    }

    &__total {
        width: 16%;
    }

    &__details {
        width: 10%;
    }

    &__order,
    &__date,
    &__status,
    &__total,
    &__details {
        display: table-cell;
        font: {
            size: rem(20px);
            weight: 400;
        }

        @media all and (min-width: 200px) and (max-width: 568px) {
            display: block;
            width: 100%;
        }

        > span {
            @media all and (min-width: 200px) and (max-width: 568px) {
                display: inline-block;
                width: 100px;
                padding-right: 10px;
                font-weight: 600;
            }
        }
    }

    &__details {

        &:last-child {

            @media all and (min-width: 568px) {
                text-align: right;
            }
        } 
    }

    &__show-on-mobile {
        @media all and (min-width: 569px) {
            display: none;
        }
    }

    &__hide-on-mobile {
        @media all and (max-width: 568px) {
            display: none;
        }
    }
}