/**
*
* Table
*
**/


.table {

    // &-basket {        
    //     @extend %latoBold;
    //     display: flex;
    //     justify-content: flex-end;
    //     // margin-bottom: 10px;
    //     font-size: 18px;
    //     color: $colorNormalGray;
    //     fill: $colorYellow;
    //     transition: all 0.25s ease;

    //     &:hover {
    //         fill: $colorGreen;
    //     }

    //     &__qty {
    //         margin-left: 10px;
    //     }
    // }

    &__form {
        display: inline;

        &-btn {
            background: transparent;
        }
    }

    &__header {
        @extend %playfairDisplayBold;
        @include dynamicFontSize(14, 16, 320, 1920);
        border-bottom: 1px solid $colorGreen;
        background-color: $colorSmokyWhite;
        // font-size: 16px;
        color: $colorGreen;

        &-select {
            min-width: 55px;
            // width: 100%;
            padding-right: 10px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            border: none;
            background: url("/images/icons/drop-down-arrow--white.svg") no-repeat;
            background-position: right center;
            background-size: 10px 10px;
            color: $colorWhite;
            cursor: pointer;
        
            &:focus {
                outline: none;
            }
        
            &::-ms-expand {
                display: none;
            }
        }

        &-option {
            color: #939393;
        }

        &-item {
            border-right: 1px solid rgba(36, 71, 34, .25);

            &:nth-child(1) {
                max-width: 204px;
                text-align: left;
            }

            &:last-child {
                border-right: 0;
            }

            &--bg-dark {
                background-color: #939393;
                color: $colorWhite;

                .table__header-link {
                    color: $colorWhite;
                }
            }
        }

        &-link {
            display: block;
            padding: 50px 20px;
            color: $colorGreen;

            &:nth-child(n+2) {
                padding: 50px 10px;
                max-width: 110px;
                width: 110px;
            }
        }

        &-arrow {
            position: relative;
            padding-right: 30px!important;

            &:after {
                display: inline-block;
                position: absolute;
                margin-left: 25px;
                margin-top: -6px;
                width: 12px;
                height: 12px;
                top: 50%;
                right: 10px;
                content: "";
                background-image: url(../images/icons/drop-down-arrow--white.svg);
                background-size: cover;
                transition: all .25s;
            }

            &--up {

                &:after {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    &__row {

        &:nth-child(even) {
            background-color: $colorSmokyWhite;
        }

        .table__col {
            border-right: 1px solid rgba(36, 71, 34, .25);

            &:nth-child(1) {
                @extend %playfairDisplayBold;
                @include dynamicFontSize(14, 16, 320, 1920);
                max-width: 204px;
                padding: 20px;
                color: $colorGreen;
            }

            &:last-child {
                // padding: 0 20px;
                border: 0;
            }

            &--bg-dark {
                background-color: #939393;
                color: $colorWhite;
            }
        }
    }

    &__col {

        &:nth-child(n+2) {
            text-align: center;
            font-size: 14px;
        }

        &:last-child {
            min-width: 280px;
        }
    }

    &-view {
        margin: 10px 0 32px;
        display: flex;

        @media all and (max-width: 768px) {
            justify-content: space-between;
            align-items: center;
        }

        &:before {

            @media all and (min-width: 768px) {
                content: "";
                display: block;
                flex: 1 1 15%;
                min-width: 250px;
            }
        }

        &__right {

            @media all and (min-width: 768px) {
                flex: 1 1 85%;
            }
        }

        &__left {
            display: none;
            fill: $colorGreen;
            background: transparent;
            font-weight: bold;
            transition: all 0.25s ease;

            @media all and (max-width: 767px) {
                display: block;
                z-index: 1;
            }

            &:hover {
                fill: $colorYellow;
            }

            &--yellow {
                fill: $colorYellow;
            }
        }

        &__icon {
            padding: 10px;
            display: inline-block;
            fill:$colorGreen;
            vertical-align: middle;
            color: $colorMain;
            transition: all 0.3s;

            @media all and (max-width: 767px) {
                padding: 5px;
            }

            &:first-child {
                margin-right: 5px;
     
                @media all and (max-width: 767px) {
                    margin-right: 0;
                }
            }

            &:hover {
                fill: $colorWhite;
                color: $colorWhite;
                background-color: $colorYellow;
            }

            svg {
                width: 24px;
                height: 24px;

                @media all and (max-width: 767px) {
                    width: 20px;
                    height: 20px;
                }
            }

            &--active {
                fill: $colorWhite;
                color: $colorWhite;
                background-color: $colorYellow;
                transition: all 0.3s;
            }
        }
    }
}
