/**
*
* Top navigation
*
**/

.l-main {
    padding-top: 140px;

    @media all and (max-width: 1150px) {
        padding-top: 30px;
    }

    &--large-pad {
        padding-top: 250px;

        @media all and (max-width: 1150px) {
            padding-top: 160px;
        }
    }

    &--sm {

        @media all and (min-width: 1024px) and (max-width: 1150px) {
            padding-top: 70px;
        }

        @media all and (max-width: 1023px) {
            padding-top: 30px;
        }
    }

    &--s {

        @media all and (max-width: 1150px) {
            padding-top: 80px;
        }
    }

    &--sm-large {
        
        padding-top: 140px;
    }

    &__circle {
        position: absolute;
        top: -115px; 
        left: 50%;
        margin-left: -220px;
        display: block;
        width: 440px;
        height: 440px;
        content: "";
        transition: all 0.25s ease;

        background-image: url(../images/Sun_circle.svg);


        @media all and (min-width: 768px) and (max-width:1400px ) {
            top: -160px; 
        }

        @media all and (max-width: 767px) {
            margin-left: -125px;
            top: -10%;
            width: 250px;
            height: 250px;
        }
    }

    &--mob-sm {

        @media all and (min-width: 768px) and (max-width: 1265px) {
            padding-top: 70px;
        }

        @media all and (min-width: 481px) and (max-width: 767px) {
            padding-top: 40px;
        }

        @media all and (max-width: 480px) {
            padding-top: 30px;
        }
    }
}